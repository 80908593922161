import type { Appointment } from '@mend/types/appointment';
import type { ScheduledUserProps } from '#components/InformationCard/Appointment/Inputs';
import type { InformationItem } from '#components/InformationCard/InformationCard';

import * as Inputs from '#components/InformationCard/Appointment/Inputs';
import InformationCard from '#components/InformationCard/InformationCard';
import Switch from '#components/Switch';
import { getFullName } from '#utils/user';

export default function AppointmentInformation({
  appointment,
  recurring,
  onRecurringChange,
  repeatOption,
  onRepeatOptionChange,
  repeatEveryOption,
  onRepeatEveryOptionChange,
  isPatient,
  scheduleProvider,
  schedulePatient,
  symptoms,
  onSymptomsChange,
}: {
  appointment: Appointment;
  scheduleProvider: ScheduledUserProps;
  schedulePatient: ScheduledUserProps;
  recurring: boolean;
  onRecurringChange: (value: boolean) => void;
  repeatOption: number;
  onRepeatOptionChange: (value: number) => void;
  repeatEveryOption: number;
  onRepeatEveryOptionChange: (value: number) => void;
  symptoms: string;
  onSymptomsChange: (value: string) => void;
  isPatient: boolean;
}): JSX.Element {
  const items = () => {
    const defaultItems: InformationItem<Appointment>[] = [
      {
        id: 'type',
        name: 'Type',
        accessor: (appointment) => appointment.appointmentType?.name ?? '',
      },
    ];

    if (isPatient) {
      defaultItems.push({
        id: 'provider',
        name: 'Provider',
        accessor: ({ provider }) => getFullName(provider),
      });
    }

    defaultItems.push(
      {
        id: 'scheduleProvider',
        name: 'Scheduled Appointment For Provider',
        accessor: function ScheduledProvider() {
          return <Inputs.ScheduledUser {...scheduleProvider} />;
        },
      },
      {
        id: 'schedulePatient',
        name: 'Scheduled Appointment For Patient',
        accessor: function ScheduledPatient() {
          return <Inputs.ScheduledUser {...schedulePatient} />;
        },
      }
    );

    if (!isPatient) {
      defaultItems.push({
        id: 'recurring',
        name: 'Recurring',
        accessor: function Recurring() {
          return (
            <Switch
              checked={recurring}
              onChange={(e) => onRecurringChange(e.target.checked)}
              inputProps={{ 'aria-label': 'Recurring' }}
            />
          );
        },
      });

      if (recurring) {
        defaultItems.push(
          {
            id: 'repeatEvery',
            name: 'Repeat Every',
            accessor: function RepeatEvery() {
              return (
                <Inputs.RepeatEvery
                  value={repeatEveryOption}
                  onChange={onRepeatEveryOptionChange}
                />
              );
            },
          },
          {
            id: 'repeat',
            name: 'Repeat',
            accessor: function Repeat() {
              return (
                <Inputs.Repeat
                  value={repeatOption}
                  onChange={onRepeatOptionChange}
                />
              );
            },
          }
        );
      }
    }

    defaultItems.push({
      id: 'symptoms',
      name: 'Symptoms',
      accessor: function Symptoms() {
        return <Inputs.Symptoms value={symptoms} onChange={onSymptomsChange} />;
      },
    });

    return defaultItems;
  };

  return (
    <InformationCard<Appointment>
      title="Appointment Information"
      model={appointment}
      items={items()}
    />
  );
}
