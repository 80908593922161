import type { PhoneNumber } from '@mend/types/phone-number';
import type { AxiosResponse } from 'axios';
import type { Response } from '#types/common';

import { api } from '../http-clients';

type PhoneLookupParams = {
  numbers: string[];
};

type PhoneLookupResponse = {
  numbers: PhoneNumber[];
};

export const phoneLookup = (
  numbersToLookup: string | string[],
  signal?: AbortSignal
): Promise<PhoneNumber[]> =>
  api
    .put<
      Response<PhoneLookupResponse>,
      AxiosResponse<Response<PhoneLookupResponse>>,
      PhoneLookupParams
    >(
      '/phone/lookup',
      {
        numbers: Array.isArray(numbersToLookup)
          ? numbersToLookup
          : [numbersToLookup],
      },
      { signal }
    )
    .then((res) => res.data.payload.numbers);
