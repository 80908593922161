import type { AppointmentTransferStatus } from '@mend/types/appointment';
import type { CellProps } from '#components/DataTable';
import type { CellRendererTransferStatusProps } from '../utils';

import { Radio } from '@mui/material';

export default function TransferStatusAction({
  row: { original: status },
  customProps,
}: CellProps<
  AppointmentTransferStatus,
  undefined,
  CellRendererTransferStatusProps
>): JSX.Element {
  return (
    <Radio
      value={status.id}
      checked={status.id === customProps?.currentStatusTypeId}
      onChange={customProps?.handleStatusType}
      inputProps={{ 'aria-label': String(status.name) }}
    />
  );
}
