import type { AppointmentTransferStatus } from '@mend/types/appointment';
import type { CellProps } from '#components/DataTable';
import type { CellRendererTransferStatusProps } from '../utils';

import { Chip, Stack, Typography } from '@mui/material';

export default function TransferStatusRadio({
  row: { original: status },
  customProps: { isInCard },
}: CellProps<
  AppointmentTransferStatus,
  CellRendererTransferStatusProps
>): JSX.Element {
  return (
    <Stack
      alignItems={isInCard ? 'start' : 'center'}
      direction={isInCard ? 'column' : 'row'}
    >
      <Chip
        label={status.name}
        sx={{
          color: status.textColor,
          backgroundColor: status.backgroundColor,
        }}
      />
      <Typography>{status.description}</Typography>
    </Stack>
  );
}
