import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import type { IconButtonProps } from '@mui/material';

import * as React from 'react';
import {
  faArrowLeftLong,
  faBars,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { styled } from '@mend/mui';
import { grey } from '@mend/mui/colors';
import { IconButton, Tooltip, Typography } from '@mui/material';

import FontAwesomeScalableIcon from '#components/FontAwesomeScalableIcon';
import * as Modal from '#components/Modal';

type Variant = 'mobile' | 'desktop';

export type PanelItem = 'patientInfo' | 'dialer' | 'notes' | 'invite';

type SidebarContextValue = {
  variant: Variant;
  activeItem: PanelItem | null;
  toggleItem: (nextItem: PanelItem) => void;
  isMobileMenuOpen: boolean;
  toggleMobileMenu: (toValue?: boolean) => void;
  panelModalLabel: string;
  setPanelModalLabel: (label: string) => void;
};

// Any default value works, it gets overwritten in `Root`
const SidebarContext = React.createContext<SidebarContextValue>({
  variant: 'desktop',
  activeItem: null,
  toggleItem: () => void 0,
  isMobileMenuOpen: false,
  toggleMobileMenu: () => void 0,
  panelModalLabel: '',
  setPanelModalLabel: () => void 0,
});
SidebarContext.displayName = 'SidebarContext';

function useSidebarContext() {
  return React.useContext(SidebarContext);
}

/**
 * Even though the sidebar only has one axis, we use grid to make use of the
 * `gap` property without issues because its usage with flexbox is limited to
 * slightly more recent browsers.
 *
 * No need to set a explicit width because a large icon button + padding = 60px
 */
const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
});

type RootProps = {
  variant: Variant;
  children: React.ReactNode;
};

export function Root(props: RootProps) {
  const [activeItem, setActiveItem] = React.useState<PanelItem | null>(null);
  const [isMobileMenuOpen, setIsMobileModalOpen] = React.useState(false);
  const [panelModalLabel, setPanelModalLabel] = React.useState('');

  const value = React.useMemo(
    () => ({
      variant: props.variant,
      activeItem,
      toggleItem(nextItem: PanelItem) {
        setActiveItem(activeItem === nextItem ? null : nextItem);
      },
      isMobileMenuOpen,
      toggleMobileMenu(toValue?: boolean) {
        setIsMobileModalOpen(toValue ?? !isMobileMenuOpen);
      },
      panelModalLabel,
      setPanelModalLabel,
    }),
    [activeItem, isMobileMenuOpen, panelModalLabel, props.variant]
  );

  return (
    <SidebarContext.Provider value={value}>
      <Container>{props.children}</Container>
    </SidebarContext.Provider>
  );
}

/**
 * For some reason the `styled` utility doesn't seem to work properly on the
 * tooltip component so we style it with the `componentsProps` prop instead.
 */
const tooltipComponentsProps = {
  tooltip: { sx: { backgroundColor: grey[800] } },
  arrow: { sx: { borderColor: grey[800], color: grey[800] } },
} as const;

/**
 * The `styled` utility handles refs in a weird way and causes issues with the
 * tooltip because it relies on its direct descendant to forward refs properly,
 * we can add a `span` element between the button and the tooltip but this
 * causes accessibility problems because the button won't be labelled by the
 * title of the tooltip this way, so we style the button with the `sx` prop
 * instead to avoid relying on `styled` and prevent these problems.
 */
const iconButtonSxProps = {
  color: 'inherit',
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: grey[800],
  },
} as const;

const ButtonListItem = styled('li')({
  margin: 0,
  padding: 0,
});

/**
 * If we were to use the mui `Button` component we'd have to tweak a lot of the
 * styles so we do it from scratch instead.
 */
const ModalButton = styled('button')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1.5),
  backgroundColor: 'inherit',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(2.5),
  border: 'none',

  '&:hover': {
    cursor: 'pointer',
  },

  '&:hover, &:focus': {
    backgroundColor: grey[900],
  },
}));

type BaseButtonProps = {
  label: string;
};

type ButtonBaseClickProps =
  | { toggles: PanelItem; onClick?: never }
  | { toggles?: never; onClick: NonNullable<IconButtonProps['onClick']> }
  | { toggles: PanelItem; onClick: NonNullable<IconButtonProps['onClick']> };

type ButtonBaseIconProps =
  | { icon: IconDefinition; customIcon?: never }
  | { icon?: never; customIcon: React.ReactElement };

/**
 * Normally we'd use composition to keep the tooltip and icon button separate
 * but since we only need one prop for the tooltip, we abstract it in a single
 * component instead.
 */
type ButtonProps = BaseButtonProps & ButtonBaseClickProps & ButtonBaseIconProps;

export function Button(props: ButtonProps) {
  const context = useSidebarContext();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (props.toggles) {
      context.toggleItem(props.toggles);

      if (context.variant === 'mobile') {
        context.setPanelModalLabel(props.label);
      }
    }

    if (context.variant === 'mobile') {
      context.toggleMobileMenu(false);
    }

    props.onClick?.(event);
  }

  return (
    <ButtonListItem>
      {context.variant === 'desktop' ? (
        <Tooltip
          title={props.label}
          placement="left"
          componentsProps={tooltipComponentsProps}
        >
          <IconButton
            size="large"
            disableRipple
            onClick={handleClick}
            sx={iconButtonSxProps}
          >
            {props.icon ? (
              <FontAwesomeScalableIcon icon={props.icon} />
            ) : (
              props.customIcon
            )}
          </IconButton>
        </Tooltip>
      ) : (
        <ModalButton onClick={handleClick}>
          {props.icon ? (
            <FontAwesomeScalableIcon icon={props.icon} />
          ) : (
            props.customIcon
          )}
          <Typography component="span" variant="h6" color="inherit">
            {props.label}
          </Typography>
        </ModalButton>
      )}
    </ButtonListItem>
  );
}

type TriggerProps = {
  defaultItem?: PanelItem;
};

export function Trigger(props: TriggerProps) {
  const context = useSidebarContext();

  if (context.variant === 'mobile') {
    return (
      <IconButton
        size="large"
        disableRipple
        onClick={() => context.toggleMobileMenu(true)}
        sx={iconButtonSxProps}
      >
        <FontAwesomeScalableIcon icon={faBars} />
      </IconButton>
    );
  }

  /**
   * Don't render anything in desktop because there is no panel available to
   * open.
   */
  if (!props.defaultItem) {
    return null;
  }

  return (
    <Button
      toggles={context.activeItem ?? props.defaultItem}
      label={context.activeItem ? 'Close' : 'Open'}
      icon={context.activeItem ? faXmark : faArrowLeftLong}
    />
  );
}

const zoomToolbarBackgroundColor = '#050505'; // One-off color
const zoomToolbarColor = '#a0a0a0'; // One-off color

/**
 * Container for the vertical list of icon buttons in the sidebar
 */
const StyledButtonList = styled(
  (props: React.HTMLAttributes<HTMLUListElement>) => (
    <ul aria-label="Video visit actions" {...props} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'variant' && prop !== 'isMobileMenuOpen',
  }
)<{ variant: Variant; isMobileMenuOpen: boolean }>(
  ({ theme, variant, isMobileMenuOpen }) => ({
    margin: 0,
    padding: variant === 'desktop' ? '6px' : 0,
    display: variant === 'desktop' || isMobileMenuOpen ? 'grid' : 'none',
    gridTemplateColumns: '1fr',
    gridAutoRows: 'min-content',
    gap: theme.spacing(variant === 'desktop' ? 3 : 1),
    backgroundColor: zoomToolbarBackgroundColor,
    color: zoomToolbarColor,
    listStyleType: 'none',
    overflow: 'auto',
  })
);

const buttonListModalCloseButtonSx = {
  backgroundColor: zoomToolbarBackgroundColor,
  color: zoomToolbarColor,

  '&:hover, &:focus': {
    backgroundColor: grey[900],
  },
} as const;

const buttonListModalHeaderSx = {
  padding: '32px 16px',
  backgroundColor: zoomToolbarBackgroundColor,
} as const;

const buttonListModalContentSx = {
  paddingTop: 0,
  borderTopColor: zoomToolbarBackgroundColor,
  backgroundColor: zoomToolbarBackgroundColor,
} as const;

type ButtonListProps = {
  children: React.ReactNode;
};

export function ButtonList(props: ButtonListProps) {
  const context = useSidebarContext();

  if (!context.isMobileMenuOpen) {
    return (
      <StyledButtonList
        variant={context.variant}
        isMobileMenuOpen={false}
        {...props}
      />
    );
  }

  /**
   * Keep the header even if empty so that the close button has its own space
   * and doesn't overlap with the content, this way the user can easily close
   * the modal without clicking on an overlapped item by mistake.
   */
  return (
    <Modal.Root
      open
      onClose={() => context.toggleMobileMenu(false)}
      aria-label="Video visit actions"
      closeButtonSxProps={buttonListModalCloseButtonSx}
      fullScreen
    >
      <Modal.Header sx={buttonListModalHeaderSx} />
      <Modal.Content sx={buttonListModalContentSx}>
        <StyledButtonList variant="mobile" isMobileMenuOpen {...props} />
      </Modal.Content>
    </Modal.Root>
  );
}

const StyledPanel = styled('div')(({ theme }) => ({
  /**
   * When the screen is < 600px, the panel is displayed in a modal so we want
   * it to fill up the space available.
   */
  width: '100%',
  height: '100%',
  padding: theme.spacing(3, 2),
  backgroundColor: grey[100],
  overflow: 'auto',

  /**
   * Starting from 600px, the panel is displayed on the page itself and the
   * total width of the container is 620px (button list 60px + panel 560) which
   * creates overflow between 600px and 620px. To prevent this, we reduce the
   * width of the panel to 540px.
   */
  '@media screen and (600px <= width < 620px)': {
    width: '540px',
  },

  '@media screen and (min-width: 620px)': {
    width: '560px',
  },
}));

const panelModalHeaderSx = { padding: '32px 16px' } as const;

const panelModalContentSx = { padding: 0 } as const;

type PanelProps = {
  children:
    | React.ReactNode
    | ((data: {
        activeItem: PanelItem;
        toggleItem: SidebarContextValue['toggleItem'];
      }) => React.ReactNode);
};

/**
 * Container for the sidebar slide out drawer
 */
export function Panel(props: PanelProps) {
  const context = useSidebarContext();

  /**
   * For some reason typescript can't infer that `activeItem` is not `null` on
   * the `onClose` prop even with the following early return, extracting it
   * does the trick, though...
   */
  const { activeItem } = context;

  if (activeItem === null) {
    return null;
  }

  const children =
    typeof props.children === 'function'
      ? props.children({ activeItem, toggleItem: context.toggleItem })
      : props.children;

  if (context.variant === 'desktop') {
    return <StyledPanel>{children}</StyledPanel>;
  }

  return (
    <Modal.Root
      open
      onClose={() => context.toggleItem(activeItem)}
      aria-label={context.panelModalLabel}
      fullScreen
    >
      <Modal.Header sx={panelModalHeaderSx} />
      <Modal.Content sx={panelModalContentSx}>
        <StyledPanel>{children}</StyledPanel>
      </Modal.Content>
    </Modal.Root>
  );
}
