import type { Patient, Provider } from '@mend/types/appointment';
import type { CountryCode } from 'libphonenumber-js/min';
import type { Properties } from '#services/api/properties.ts';

import { parsePhoneNumberFromString } from 'libphonenumber-js/min';

import { ORG_SETTINGS_SPEED_DIALS } from '#utils/properties.ts';

export type SpeedDial = {
  extension?: string;
  formattedNumber: string;
  id: number;
  name: string;
  number: string;
};

function convertToNationalFormat(number: string | number): string {
  // Ensure that the input is a string before parsing
  const numberAsString = String(number);
  return (
    parsePhoneNumberFromString(numberAsString)?.formatNational() ??
    numberAsString
  );
}

export function getSpeedDials(
  properties: Properties | null,
  provider: Provider | undefined,
  patient: Patient | undefined
): SpeedDial[] {
  let speedDials: SpeedDial[] = [];

  // Extract speed dials from the org properties
  try {
    speedDials = JSON.parse(
      (properties?.[ORG_SETTINGS_SPEED_DIALS] as string | undefined) ?? '[]'
    ) as SpeedDial[];

    // Format number, filter out speed dials with extensions, and add patient/provider in one step
    speedDials = [
      patient?.mobile
        ? {
            formattedNumber: convertToNationalFormat(patient.mobile),
            id: patient.id,
            name: `${patient.firstName} ${patient.lastName} on Appointment`,
            number: patient.mobile,
          }
        : null,
      provider?.mobile
        ? {
            formattedNumber: convertToNationalFormat(provider.mobile),
            id: provider.id,
            name: `${provider.firstName} ${provider.lastName} on Appointment`,
            number: provider.mobile,
          }
        : null,
      ...speedDials.map((dial) =>
        dial.extension
          ? null
          : { ...dial, formattedNumber: convertToNationalFormat(dial.number) }
      ),
    ].filter((dial): dial is SpeedDial => dial !== null); // Filter out nulls
  } catch (error) {
    console.error('Error parsing speed dials:', error);
  }

  return speedDials;
}

export const getCountryCode = (value: string): CountryCode | undefined =>
  !value.startsWith('+') ? 'US' : undefined;

export const parsePhoneNumber = (
  value: string
): ReturnType<typeof parsePhoneNumberFromString> =>
  parsePhoneNumberFromString(value, getCountryCode(value));
