import type { Appointment } from '@mend/types/appointment';
import type { User } from '@mend/types/user';
import type { SlotRange } from '#components/TransferVisitModal/utils';

import { styled } from '@mend/mui';
import { AddOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';

import ProvidersAutocomplete from '#components/Autocomplete/ProvidersAutocomplete';
import ProviderAvailabilityModal from '#components/ProviderAvailability/ProviderAvailabilityModal';
import { useModal } from '#hooks';

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'row',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

/**
 * The TextField and Button have different heights so the 2px for the margin
 * makes it look centered.
 */
const ChangeProviderButton = styled(Button)(({ theme }) => ({
  marginBottom: 2,
  flexShrink: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: 0,
  },
}));

const providersShownInAppointmentFlowDataFilter = (providers: User[]) =>
  providers.filter((provider) => Boolean(provider.showInAppointmentFlow));

type ProviderProps = {
  provider: User;
  onProviderChange: (provider: User) => void;
  appointment: Appointment;
  onApplyProviderSelection: (user: User, selectedSlot: SlotRange) => void;
};

export default function Provider({
  provider,
  onProviderChange,
  appointment,
  onApplyProviderSelection,
}: ProviderProps): JSX.Element {
  const { isOpen, open, dismiss } = useModal();

  return (
    <>
      <Container>
        <ProvidersAutocomplete
          disablePortal
          dataFilter={providersShownInAppointmentFlowDataFilter}
          disableClearable
          inputLabel="Provider"
          value={provider}
          onChange={(_event, value) => onProviderChange(value)}
        />

        <ChangeProviderButton
          color="primary"
          onClick={open}
          startIcon={<AddOutlined />}
        >
          Change Provider
        </ChangeProviderButton>
      </Container>

      {isOpen && (
        <ProviderAvailabilityModal
          isOpen
          appointment={appointment}
          onDismiss={dismiss}
          onApplyProviderSelection={onApplyProviderSelection}
        />
      )}
    </>
  );
}
