import type { BackgroundEvent } from '@mend/types/appointment';
import type { Group } from '@mend/types/group';
import type { Org } from '@mend/types/org';
import type { User } from '@mend/types/user';
import type { Response } from '#types/common.ts';

import { removeCookie, setCookie } from '#utils/cookies.ts';
import { syncLanguageCookie } from '#utils/languages.ts';
import { api } from '../http-clients.ts';

type GetMeResponsePayload = {
  user: User;
  org: Org;
};

/**
 * Save the logo (if present) in a cookie with the same format as in the
 * portal, otherwise remove the cookie.
 */
function updateLogoCookie(logoUri: Org['settings']['logoUri']): void {
  if (logoUri) {
    setCookie('last-logo', `/file/${logoUri}`);
  } else {
    removeCookie('last-logo');
  }
}

export function getMe(): Promise<GetMeResponsePayload> {
  return api
    .get<Response<GetMeResponsePayload>>('/user/me')
    .then((response) => {
      updateLogoCookie(response.data.payload.org.settings.logoUri);
      return response.data.payload;
    });
}

export type UserSettings = {
  language: string;
};

export function getSettings(): Promise<UserSettings> {
  return api
    .get<Response<{ userSettings: UserSettings }>>('/user/settings')
    .then((res) => res.data.payload.userSettings)
    .then((settings) => {
      syncLanguageCookie(settings.language);
      return settings;
    });
}

export type GetProvidersParams = {
  showInAppointmentFlow?: 1 | 0;
  providerAvailability?: 1 | 0;
  onlyAvailable?: 1 | 0;
  appointmentTypeId?: number;
  eventStartDate?: string;
  eventEndDate?: string;
  patientAge?: number;
  excludeUsers?: number[];
  search?: string;
  activeDisplayedProviders?: 1 | 0;
  inactiveDisplayedProviders?: 1 | 0;
  limit?: number;
  order?: string;
};

export type GetProvidersResponse = {
  providers: User[];
  isPageable?: boolean;
  limit?: number | null;
  page?: number | null;
  totalItems?: number;
  totalPages?: number;
};

export function getProviders(params?: GetProvidersParams): Promise<User[]> {
  return api
    .get<Response<GetProvidersResponse>>('/provider', {
      params,
    })
    .then((res) => res.data.payload.providers);
}

export type GetUsersParams = {
  id?: number;
  role?: User['role'][];
  excludeUsers?: number[];
  search?: string;
  page?: number;
  limit?: number;
  order?: string;
  includeGroups?: 0 | 1;
  includeTemporary?: 0 | 1;
};

export type GetUsersResponse = {
  groups?: Group[];
  users: User[];
  isPageable?: boolean;
  limit?: number | null;
  page?: number | null;
  totalItems?: number;
  totalPages?: number;
};

export function getUsers(
  params?: GetUsersParams,
  signal?: AbortSignal
): Promise<GetUsersResponse> {
  return api
    .get<Response<GetUsersResponse>>('/user', {
      params,
      signal,
    })
    .then((res) => res.data.payload);
}

export function getUser(userId: User['id']): Promise<User> {
  return api
    .get<Response<{ user: User }>>(`/user/${userId}`)
    .then((res) => res.data.payload.user);
}

type GetProviderUnavailabilityParams = {
  startDate: string;
  endDate: string;
  timeZone: string;
  appointmentTypeId?: number;
  addressId?: number;
};

export type GetProviderUnavailabilityResponse = {
  events: BackgroundEvent[];
};

export function getProviderUnavailability(
  providerId: number,
  params?: GetProviderUnavailabilityParams
): Promise<BackgroundEvent[]> {
  return api
    .get<
      Response<GetProviderUnavailabilityResponse>
    >(`/provider/${providerId}/unavailableSlots`, { params })
    .then((res) => res.data.payload.events);
}
