import type { Appointment } from '@mend/types/appointment';
import type { User } from '@mend/types/user';
import type { CreateAppointmentParams } from '#services/api/appointment';
import type { AppointmentDateRange } from '../../BookAgainCalendar';

import * as React from 'react';
import { Button } from '@mui/material';
import { addDays } from 'date-fns';

import DependentInformationCard from '#components/InformationCard/Dependent/DependentInformation';
import PatientInformationCard from '#components/InformationCard/PatientInformation';
import * as Modal from '#components/Modal/Modal';
import * as SideScroll from '#components/SideScroll';
import { convertUtcStringToLocalDate } from '#utils/dates';
import { roleGreaterOrEquals } from '#utils/roles';
import { getFullName } from '#utils/user';
import AppointmentInformationCard from './AppointmentInformationCard';

export type FollowUpProps = {
  user: User | undefined;
  createAppointmentsIsPending: boolean;
  onBookAgainAppointment: (
    newAppointment?: CreateAppointmentParams | null
  ) => void;
  onGoToCalendar: () => void;
  currentProviderSelected: User;
  newAppointmentsRange: AppointmentDateRange[];
  onNewAppointmentsRangeChange: (value: AppointmentDateRange[]) => void;
  recurring: boolean;
  onRecurringChange: (value: boolean) => void;
  appointment: Appointment;
  onGetBookAgainAppointmentParams: () => CreateAppointmentParams | null;
  symptoms: string;
  onSymptomsChange: (value: string) => void;
  properties?: Record<string, unknown>;
};

export default function FollowUp({
  properties,
  user,
  createAppointmentsIsPending,
  onBookAgainAppointment,
  onGetBookAgainAppointmentParams,
  appointment,
  newAppointmentsRange,
  onNewAppointmentsRangeChange,
  recurring,
  onRecurringChange,
  onGoToCalendar,
  currentProviderSelected,
  symptoms,
  onSymptomsChange,
}: FollowUpProps): JSX.Element {
  const isPatient = user?.role === 'Patient';
  const [repeat, setRepeat] = React.useState(1);
  const [repeatEvery, setRepeatEvery] = React.useState(1);

  const showDependent = React.useMemo(() => {
    if (!properties || !user) return;
    return (
      properties?.['org.settings.signup.enablePatientDependents'] ||
      roleGreaterOrEquals(user.role, 'Staff')
    );
  }, [properties, user]);

  const handleRecurringEvents = () => {
    function getRanges(
      frequency: number,
      startDate: string,
      endDate: string,
      times: number
    ): AppointmentDateRange[] {
      const range = {
        start: addDays(new Date(startDate), frequency).toISOString(),
        end: addDays(new Date(endDate), frequency).toISOString(),
      };
      if (times <= 1) {
        return [range];
      }
      return [
        range,
        ...getRanges(frequency, range.start, range.end, times - 1),
      ];
    }

    // TODO tech debt added for the port
    const { start, end } = newAppointmentsRange[0]!;
    onNewAppointmentsRangeChange(
      newAppointmentsRange.concat(getRanges(repeatEvery, start, end, repeat))
    );
    onRecurringChange(true);
    onGoToCalendar();
  };

  const { patient, startDate, endDate } = appointment;
  const appointmentRange = (newAppointmentsRange[0] as
    | AppointmentDateRange
    | undefined) ?? {
    start: convertUtcStringToLocalDate(startDate).toISOString(),
    end: convertUtcStringToLocalDate(endDate).toISOString(),
  };

  return (
    <>
      <Modal.Content>
        <SideScroll.Root>
          <SideScroll.List>
            <SideScroll.ListItem
              id="patient-information"
              label="Patient Information"
            />
            {showDependent && (
              <SideScroll.ListItem
                id="dependent-information"
                label="Dependent Information"
              />
            )}
            <SideScroll.ListItem
              id="appointment-information"
              label="Appointment Information"
            />
          </SideScroll.List>

          <SideScroll.Panels>
            <SideScroll.Panel id="patient-information">
              <PatientInformationCard appointment={appointment} />
            </SideScroll.Panel>
            <SideScroll.Panel id="dependent-information">
              <DependentInformationCard appointment={appointment} />
            </SideScroll.Panel>
            <SideScroll.Panel id="appointment-information">
              <AppointmentInformationCard
                appointment={appointment}
                isPatient={isPatient}
                scheduleProvider={{
                  range: appointmentRange,
                  userName: getFullName(currentProviderSelected),
                  timezone: currentProviderSelected.timeZone,
                }}
                schedulePatient={{
                  range: appointmentRange,
                  userName: getFullName(patient),
                  timezone: patient.timeZone,
                }}
                recurring={recurring}
                onRecurringChange={onRecurringChange}
                repeatEveryOption={repeatEvery}
                onRepeatEveryOptionChange={setRepeatEvery}
                repeatOption={repeat}
                onRepeatOptionChange={setRepeat}
                symptoms={symptoms}
                onSymptomsChange={onSymptomsChange}
              />
            </SideScroll.Panel>
          </SideScroll.Panels>
        </SideScroll.Root>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="outlined" onClick={onGoToCalendar}>
          Back
        </Button>
        <Button
          disabled={createAppointmentsIsPending}
          onClick={() =>
            recurring
              ? handleRecurringEvents()
              : onBookAgainAppointment(onGetBookAgainAppointmentParams())
          }
        >
          Schedule Follow Up
        </Button>
      </Modal.Footer>
    </>
  );
}
