import { setAccessToken } from './cookies';

export function extractTokenFromUrl() {
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  if (token) {
    setAccessToken(token);

    // Remove the token from the URL by replacing the current history state
    url.searchParams.delete('token');
    window.history.replaceState({}, '', url.pathname + url.search);
  }
}
