import type { SxProps } from '@mui/material';

import { mendTeal, red } from '@mend/mui/colors';
import { EditOutlined, RemoveCircleOutline } from '@mui/icons-material';

import * as ActionPrimitive from '../Action';

type Icon = typeof EditOutlined; // Any will do

type Action = 'edit' | 'remove';

const data: Record<Action, { label: string; Icon: Icon; sx: SxProps }> = {
  edit: {
    label: 'Edit',
    Icon: EditOutlined,
    sx: {
      color: 'white',
      borderColor: mendTeal[700],
      backgroundColor: mendTeal[700],
      ':hover': {
        borderColor: mendTeal[800],
        backgroundColor: mendTeal[800],
      },
      ':focus': {
        borderColor: mendTeal[1000],
        backgroundColor: mendTeal[800],
      },
    },
  },
  remove: {
    label: 'Remove',
    Icon: RemoveCircleOutline,
    sx: {
      color: 'white',
      borderColor: red[600],
      backgroundColor: red[600],
      ':hover': {
        borderColor: red[700],
        backgroundColor: red[700],
      },
      ':focus': {
        borderColor: red[900],
        backgroundColor: red[700],
      },
    },
  },
};

type ActionProps = {
  action: Action;
  onClick: () => void;
};

export default function Action({ action, onClick }: ActionProps): JSX.Element {
  const { label, sx, Icon } = data[action];
  const id = `transfer-visit-action-${action}`;
  return (
    <ActionPrimitive.Root>
      <ActionPrimitive.Button aria-labelledby={id} onClick={onClick} sx={sx}>
        <Icon />
      </ActionPrimitive.Button>
      <ActionPrimitive.Label id={id}>{label}</ActionPrimitive.Label>
    </ActionPrimitive.Root>
  );
}
