import type { TablePaginationProps } from '@mui/material';
import type * as React from 'react';
import type { TableInstance } from 'react-table';
import type { Status as PromiseStatus } from '#types/common';

import { styled } from '@mend/mui';
import { ClearOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';

type QueryStatus = 'idle' | 'loading' | 'success' | 'error';

const defaultPageSizes = [5, 10, 15, 20, 25, 30];

const LoaderContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
}));

const StyledPagination = styled(TablePagination)(
  ({ theme: { breakpoints } }) => ({
    flexShrink: 0,
    [breakpoints.down('md')]: {
      '& .MuiTablePagination-toolbar': {
        justifyContent: 'center',
        flexWrap: 'wrap',
      },
      '& .MuiTablePagination-actions': {
        margin: '0 !important',
      },
    },
  })
) as typeof TablePagination;

const defaultSelectProps: TablePaginationProps['SelectProps'] = {
  inputProps: { 'aria-label': 'Results per page' },
  native: true,
};

export const Pagination = ({
  labelRowsPerPage = 'Results per page:',
  showFirstButton = true,
  showLastButton = true,
  SelectProps = defaultSelectProps,
  ...props
}: TablePaginationProps): JSX.Element => (
  <StyledPagination
    component="div"
    labelRowsPerPage={labelRowsPerPage}
    showFirstButton={showFirstButton}
    showLastButton={showLastButton}
    SelectProps={SelectProps}
    {...props}
  />
);

export type DataControlsProps<T extends Record<string, unknown>> = {
  containerRef: React.Ref<HTMLDivElement>;
  instance: TableInstance<T>;
  /**
   * Total number of records in the table. Needed when doing server side
   * pagination, otherwise we use the length of rows coming from the instance.
   */
  totalRecords?: number;
  /**
   * Options to have in the select for the page size.
   */
  pageSizes?: number[];
  /**
   * Status of the request in charge of fetching the data. It accepts both our
   * status that we use internally and the one from react-query. Can be omitted
   * if the data is already loaded and no server side pagination is used.
   */
  status?: PromiseStatus | QueryStatus;
  /**
   * Message used when there are no results.
   */
  isEmptyMessage?: string;
  /**
   * Message used when something went wrong fetching the data.
   */
  isRejectedMessage?: string;
  /**
   * Slot that places content right above the table.
   */
  headerSlot?: React.ReactNode;
  /**
   * Slot that places content to the left side of the table pagination
   * component.
   */
  footerSlot?: React.ReactNode;
  /**
   * Make the table container have a visible overflow if `true` so the shadows
   * of the cards are displayed properly.
   */
  forList?: boolean;
  /**
   * Change wording in selection depending on the type of Element that uses it.
   */
  selectWord?: 'Selected' | 'Added';
  /**
   * Determine if the `selectWord n | Clear all` controls should be displayed.
   */
  showSelectedControls?: boolean;
  children: React.ReactNode;
};

export default function DataControls<T extends Record<string, unknown>>({
  containerRef,
  instance,
  totalRecords,
  pageSizes = defaultPageSizes,
  status = 'success',
  headerSlot,
  footerSlot,
  children,
  forList = false,
  isEmptyMessage = 'No results found.',
  isRejectedMessage = 'Something went wrong.',
  selectWord = 'Selected',
  showSelectedControls = true,
}: DataControlsProps<T>): JSX.Element {
  const {
    rows,
    page,
    gotoPage,
    setPageSize,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = instance;

  const hasRowSelect = selectedRowIds !== undefined && showSelectedControls;
  const totalSelectedRows = hasRowSelect
    ? Object.keys(selectedRowIds).length
    : 0;

  const hasPagination = page !== undefined;
  const actualRows = hasPagination ? page : rows;

  const isSuccess = status === 'resolved' || status === 'success';
  const isLoading =
    status === 'idle' || status === 'pending' || status === 'loading';
  const isError = status === 'rejected' || status === 'error';
  const isEmpty = isSuccess && actualRows.length === 0;

  return (
    <TableContainer
      ref={containerRef}
      sx={{
        position: 'relative',
        overflowX: forList ? 'visible' : 'hidden',
      }}
    >
      {headerSlot && <Box py={2}>{headerSlot}</Box>}

      {hasRowSelect && (
        <Stack alignItems="center" justifyContent="flex-end" py={2}>
          <Typography component="span" variant="body1" color="grey.600">
            {`${totalSelectedRows} ${selectWord}`}
            <Box component="span" display="inline-block" ml={1}>
              {' | '}
            </Box>
          </Typography>
          <Button
            variant="text"
            startIcon={<ClearOutlined />}
            disabled={totalSelectedRows === 0}
            onClick={() => toggleAllRowsSelected(false)}
          >
            Clear all
          </Button>
        </Stack>
      )}

      {children}

      {(isEmpty || isError) && (
        <Typography variant="body1" my={2} textAlign="center">
          {isEmpty ? isEmptyMessage : isRejectedMessage}
        </Typography>
      )}

      {(Boolean(footerSlot) || hasPagination) && (
        <Stack
          py={2}
          alignItems="center"
          justifyContent="space-between"
          direction={{ xs: 'column', md: 'row' }}
        >
          <div>{footerSlot}</div>

          {hasPagination && (
            <Pagination
              count={totalRecords ?? rows.length}
              page={pageIndex}
              onPageChange={(_event, page) => gotoPage(page)}
              rowsPerPage={pageSize}
              rowsPerPageOptions={pageSizes}
              onRowsPerPageChange={(event) =>
                setPageSize(Number(event.target.value))
              }
            />
          )}
        </Stack>
      )}

      {isLoading && (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      )}
    </TableContainer>
  );
}
