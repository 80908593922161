import type { Appointment, Symptom, Type } from '@mend/types/appointment';
import type { Org } from '@mend/types/org';
import type { User } from '@mend/types/user';
import type { Properties } from '#services/api/properties.ts';

import { format, utcToZonedTime } from 'date-fns-tz';

import { ALLOW_PATIENT_REBOOK, ALLOW_STAFF_SCHEDULING } from './properties.ts';
import { roleGreaterOrEquals } from './roles.ts';

const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function getStartDateForMessageTemplate({
  startDate,
  provider,
}: Appointment): string {
  const timeZone = provider.timeZone ?? defaultTimeZone;
  const zonedDate = utcToZonedTime(`${startDate.replace(' ', 'T')}Z`, timeZone);
  return format(zonedDate, 'MM/dd/yyyy h:mm aaa OOOO', { timeZone });
}

export function getSymptoms(
  appointment: Appointment,
  symptoms: string
): Appointment['symptoms'] | Pick<Symptom, 'content'>[] {
  const content = symptoms.trim();

  return (Array.isArray(appointment.symptoms) &&
    appointment.symptoms.length === 0) ||
    appointment.symptoms === null
    ? symptoms
      ? [{ content }]
      : []
    : [
        { ...appointment.symptoms[0], content },
        ...appointment.symptoms.slice(1),
      ];
}

export function isSingleOrGroup(
  type: Type | undefined,
  expected: Extract<Type, 'Video' | 'In Office' | 'Phone' | 'Messaging'>
): boolean {
  return type ? type.replace('Group ', '') === expected : false;
}

export function isInPerson(type: Type | undefined): boolean {
  return type
    ? type === 'Office Video' || isSingleOrGroup(type, 'In Office')
    : false;
}

/**
 * Determine if the appointment meets the criteria to be booked again. This
 * replicates the logic present in the new dashboard with the following changes:
 * - No check for cancel/reschedule request (doesn't apply here)
 * - The `Messaging` type accounts for its group variant too, _probably_ an
 *   oversight in the new dashboard
 */
export function canBeBookedAgain(data: {
  properties?: Properties | null;
  user?: User | null;
  org?: Org | null;
  appointment?: Appointment | null;
}): boolean {
  const isGroup = Boolean(data.appointment?.groupId);
  const isStaffPlus = roleGreaterOrEquals(data.user?.role, 'Staff');
  const allowStaffScheduling = data.properties?.[ALLOW_STAFF_SCHEDULING] === 1;
  const shouldRestrictBooking = isStaffPlus
    ? !allowStaffScheduling
    : Boolean(data.user?.restrictedBooking);
  const allowPatientRebook = data.properties?.[ALLOW_PATIENT_REBOOK] === 1;
  const hasValidType = data.appointment?.appointmentType
    ? !isSingleOrGroup(data.appointment.appointmentType.type, 'Messaging')
    : false;

  return (
    !isGroup &&
    !shouldRestrictBooking &&
    ((isStaffPlus && allowStaffScheduling) ||
      (Boolean(data.org?.settings.allowPatientBookings) &&
        allowPatientRebook)) &&
    hasValidType
  );
}
