import type { Appointment } from '@mend/types/appointment';
import type { User } from '@mend/types/user';
import type { LayoutOutletContext } from '#routes/Layout/Layout.types';
import type { LoaderData } from './Session.data';

import * as React from 'react';
import { createPortal } from 'react-dom';
import {
  faArrowRightArrowLeft,
  faBan,
  faCalendarPlus,
  faCircleInfo,
  faCirclePlus,
  faNote,
  faPhone,
  // faClipboard,
} from '@fortawesome/pro-regular-svg-icons';
import { styled } from '@mend/mui';
import { useMediaQuery, useTheme } from '@mui/material';
import { useMachine } from '@xstate/react';
import { format } from 'date-fns';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min';
import { useSnackbar } from 'notistack';
import {
  useLoaderData,
  useOutletContext,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';

import { SnackbarCloseButton } from '#/components/SnackbarCloseButton.tsx';
import { ZoomButton } from '#/components/ZoomButton';
import invalidSessionCodeUrl from '#assets/images/errors/invalid-session-code.png';
import ZoomLogo from '#assets/images/zoom-logo.svg?react';
import BookAgainModal from '#components/BookAgainModal/BookAgainModal.tsx';
import TransferVisitModal from '#components/TransferVisitModal/TransferVisitModal';
import useModal from '#hooks/modal';
import { usePusherOrgStaffChannel } from '#hooks/pusher-org-staff-channel.ts';
import useReportToNewRelic from '#hooks/report-to-new-relic.ts';
import GenericRouteError from '#routes/GenericRouteError';
import RouteError from '#routes/RouteError';
import { Dialer } from '#routes/Session/Dialer.tsx';
import { getAppointment } from '#services/api/appointment.ts';
import { logZoomData } from '#services/logging';
import {
  convertUtcStringToLocalDate,
  isAppointmentWithinRange,
} from '#utils/dates.ts';
import { isEndAllAndCheckOutEnabled } from '#utils/properties';
import { isLanguageSupportedByZoom } from '#utils/zoom';
import CheckOutModal from './CheckOutModal';
import Invite from './Invite';
import { JoinInZoomModal } from './JoinInZoomModal';
import { JoinMethod } from './JoinMethod.tsx';
import { KeepAlive } from './KeepAlive/KeepAlive.tsx';
import NoShowModal from './NoShowModal.tsx';
import { Notes } from './Notes';
import { InformationPanel } from './PatientInformation/InformationPanel.tsx';
import { sessionMachine } from './Session.machine';
import {
  getZoomErrorDataPayload,
  getZoomStatisticsDataPayload,
  InvalidLinkError,
  messageSchema,
} from './Session.utils';
import * as Sidebar from './Sidebar';

const Grid = styled('div')({
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr min-content',
  overflow: 'auto',
});

const ZoomIframeContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',

  '.zoom-iframe': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    border: 'none',
  },
});

const USER_ALERT_BEFORE_JOIN_WINDOW = 2; // hours
const USER_ALERT_AFTER_JOIN_WINDOW = 22; // hours

/**
 * At some point in the future this session route will dictate which vendor to
 * use for the video call, in the meantime, it renders the zoom iframe.
 */
export function Session() {
  const data = useLoaderData() as LoaderData;

  /**
   * We save the original appointment in state because some actions (such as
   * transferring the visit) will change the appointment, and we want to
   * always have the most recent version of it.
   */
  const [appointment, setAppointment] = React.useState(data.appointment);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [searchParams] = useSearchParams();

  const alertedAppointmentsRef = React.useRef<number[]>([]);

  const [snapshot, send] = useMachine(
    sessionMachine.provide({
      actions: {
        showBadNetworkQualityToast: () => {
          enqueueSnackbar(`Having connection issues?`, {
            preventDuplicate: true,
            persist: true,
            action: (key) => (
              <>
                <ZoomButton
                  size="small"
                  href={data.joinUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={{ marginX: theme.spacing(0.625) }}
                  onClick={() => {
                    zoomIframeRef.current?.contentWindow?.postMessage(
                      { type: 'patient.joined-in-zoom' },
                      window.location.origin
                    );
                    closeSnackbar(key);
                  }}
                >
                  Join with Zoom App
                </ZoomButton>
                <SnackbarCloseButton
                  onClick={() => {
                    closeSnackbar(key);
                  }}
                />
              </>
            ),
          });
        },
        showCallingToast: ({ event }) => {
          if (event.type !== 'visit.callout.dialing') {
            return;
          }
          const { inviteeName, inviteeNumber } = event;
          const inviteeNameOrNumber = inviteeName ?? inviteeNumber;
          enqueueSnackbar(`Calling ${inviteeNameOrNumber}...`, {
            variant: 'info',
            action: (key) => (
              <SnackbarCloseButton onClick={() => closeSnackbar(key)} />
            ),
          });
        },
        showDisconnectedToast: () => {
          enqueueSnackbar(`Call Disconnected`, {
            variant: 'info',
            action: (key) => (
              <SnackbarCloseButton onClick={() => closeSnackbar(key)} />
            ),
          });
        },
        showInvalidNumberToast: ({ context: { phoneNumber } }) => {
          enqueueSnackbar(
            `Call to ${phoneNumber} failed. Check the number and try again.`,
            {
              variant: 'error',
              persist: true,
              preventDuplicate: true,
              action: (key) => (
                <SnackbarCloseButton onClick={() => closeSnackbar(key)} />
              ),
            }
          );
        },
        showCallFailedToast: () => {
          enqueueSnackbar(`Call Failed`, {
            variant: 'error',
            persist: true,
            preventDuplicate: true,
            action: (key) => (
              <SnackbarCloseButton onClick={() => closeSnackbar(key)} />
            ),
          });
        },
        showCallAcceptedToast: () => {
          enqueueSnackbar(`Call accepted. Participant joining shortly.`, {
            variant: 'info',
            action: (key) => (
              <SnackbarCloseButton onClick={() => closeSnackbar(key)} />
            ),
          });
        },
      },
      guards: {
        isOpenedInPip: () => searchParams.get('isOpenedInPip') === 'true',
        isAdHocVisit: () => !data.session.appointmentId,
        isStaffPlus: () => data.isStaffPlus,
        isKioskUser: () => data.isKioskUser,
        isEndAllAndCheckOutEnabled: () =>
          isEndAllAndCheckOutEnabled(data.properties),
        canPatientJoinViaApp: () =>
          Boolean(data.properties['video.zoom.canPatientsUseApp'] ?? true),
      },
    })
  );

  usePusherOrgStaffChannel('outbound-call', (message) => {
    if (message.videoSessionId !== parseInt(data.session.id)) {
      return;
    }
    send({ type: `visit.callout.${message.action}` });
  });

  usePusherOrgStaffChannel('user-connection', (message) => {
    // only consider moving forward if
    // - the providerId on the message is the same as the providerId on the appointment or the current user's id.
    //   This is skipping the isProvider check because only providers can be set on providerId
    // - the appointmentId has not been alerted before
    if (
      message.action !== 'connect' ||
      (!data.session.appointmentId && !data.isProvider) ||
      (data.session.appointmentId &&
        message.appointmentId === parseInt(data.session.appointmentId)) ||
      ![data.user?.id, data.appointment?.providerId].includes(
        message.providerId
      ) ||
      alertedAppointmentsRef.current.includes(message.appointmentId)
    ) {
      return;
    }

    // Add the appointmentId to the array
    alertedAppointmentsRef.current.push(message.appointmentId);

    getAppointment(message.appointmentId)
      .then((appointment) => {
        // Skip alerts for appointments that are not within the alert window
        if (
          !isAppointmentWithinRange(
            appointment,
            USER_ALERT_BEFORE_JOIN_WINDOW,
            USER_ALERT_AFTER_JOIN_WINDOW
          )
        ) {
          return;
        }
        const start = format(
          convertUtcStringToLocalDate(appointment.startDate),
          'h:mmaaa'
        );

        const subject =
          appointment.patientId === message.user.id
            ? `${appointment.patient.firstName} ${appointment.patient.lastName}`
            : 'Someone';

        enqueueSnackbar(
          `${subject} has joined for the ${start} video session.`,
          {
            variant: 'info',
            autoHideDuration: 10000,
            action: (key) => (
              <SnackbarCloseButton onClick={() => closeSnackbar(key)} />
            ),
          }
        );
      })
      .catch((error) => {
        console.error('Failed to alert provider of patient joining.', error);
      });
  });

  /**
   * Flag used to know when the user has passed the preview/waiting screens and
   * is now in the video call. It gets toggled from an event dispatched by the
   * iframe.
   */
  const [isUserInVideoScreen, setIsUserInVideoScreen] = React.useState(false);

  React.useEffect(() => {
    function handler(event: MessageEvent<unknown>) {
      // Disregard messages from other origins as a security measure
      if (event.origin !== window.location.origin) return;
      const parsed = messageSchema.safeParse(event.data);
      if (!parsed.success) {
        return;
      }
      switch (parsed.data.type) {
        case 'visit.init.error':
          send({ type: 'visit.init.error' });
          break;
        case 'visit.screen.video':
          setIsUserInVideoScreen(parsed.data.active);
          break;
        case 'visit.ended':
          send({ type: 'visit.ended', force: parsed.data.force });
          break;
        case 'visit.networkQualityChanged':
          send({
            type: 'visit.networkQualityChanged',
            level: parsed.data.level,
          });
          break;
        case 'visit.statistics':
          // This event should not be dispatched/received for ad-hoc visits
          if (!data.session.appointmentId) return;
          logZoomData(
            getZoomStatisticsDataPayload({
              loaderData: data,
              eventData: parsed.data,
            })
          ).catch((error) => {
            console.error('Failed to log zoom statistics data.', error);
          });
          break;
        case 'zoom.error':
          logZoomData(
            getZoomErrorDataPayload({
              loaderData: data,
              eventData: parsed.data,
            })
          ).catch((error) => {
            console.error('Failed to log zoom error data.', error);
          });
          break;
        case 'zoom.callout.error':
          send({ type: 'visit.callout.error' });
          break;
      }
    }
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [data, send]);

  const zoomIframeRef = React.useRef<HTMLIFrameElement>(null);

  const transferVisitModal = useModal();

  function isTransferVisitActionEnabled(
    appointment: Appointment | null
  ): appointment is Appointment {
    return appointment !== null && data.appointmentStatuses.length > 0;
  }

  function handleTransferVisitSuccess(updatedAppointment: Appointment) {
    setAppointment(updatedAppointment);
    transferVisitModal.dismiss();
  }

  const noShowModal = useModal();

  function handleSuccessfulNoShow() {
    zoomIframeRef.current?.contentWindow?.postMessage(
      { type: 'staff.end-for-all' },
      window.location.origin
    );
  }

  const bookAgainModal = useModal();

  const joinInZoomModal = useModal();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const { videoSidebarSlot } = useOutletContext<LayoutOutletContext>();

  /**
   * Since we can't trigger the route's error element from here, we render the
   * same component used there if an unrecoverable state is reached.
   */
  if (snapshot.matches('error')) {
    return <GenericRouteError />;
  }

  if (snapshot.matches('joinMethod')) {
    return (
      <JoinMethod
        visitWith={data.visitWith}
        joinViaAppUrl={data.joinUrl}
        onJoinViaWeb={() => send({ type: 'join.via.web' })}
      />
    );
  }

  const sidebar =
    isMobile && videoSidebarSlot !== null
      ? ({ variant: 'mobile', slot: videoSidebarSlot } as const)
      : ({ variant: 'desktop' } as const);

  /**
   * This function is used to make a call from the dialer component
   */
  function makeCall(phoneNumber: string, userName: string = '') {
    let cleansedNumber = phoneNumber.replace(/[^0-9+]/g, '');
    let nationalNumber = phoneNumber;

    const objPhone = parsePhoneNumberFromString(phoneNumber);
    if (objPhone) {
      cleansedNumber = objPhone.formatInternational().replace(/[^0-9+]/g, '');
      nationalNumber = objPhone.formatNational();
    }

    userName = userName || nationalNumber;
    // Emit event to iframe to initiate call
    zoomIframeRef.current?.contentWindow?.postMessage(
      { type: 'staff.call-out', phoneNumber: cleansedNumber, userName },
      window.location.origin
    );
    send({
      type: 'visit.callout.dialing',
      inviteeName: userName,
      inviteeNumber: nationalNumber,
    });
  }

  return (
    <>
      <Grid>
        <ZoomIframeContainer
          className={
            isLanguageSupportedByZoom(data.langShortCode) &&
            data.langShortCode !== 'en'
              ? 'OneLinkNoTx'
              : ''
          }
        >
          <iframe
            ref={zoomIframeRef}
            title="Zoom client"
            className="zoom-iframe"
            src={`${import.meta.env.BASE_URL}zoom/index.html?${data.zoomIframeSearchParams}`}
            allow="camera; microphone"
          />
        </ZoomIframeContainer>

        {isUserInVideoScreen && !data.isKioskUser && (
          <Sidebar.Root key={sidebar.variant} variant={sidebar.variant}>
            <Sidebar.ButtonList>
              {/*
                TODO: As of TS-13702, no actions exist for guests other than
                joining in app (which doesn't require a sidebar panel), once this
                changes, the following condition should likely be updated and
                this comment removed.
                */}
              {data.isStaffPlus ? (
                sidebar.variant === 'mobile' ? (
                  createPortal(<Sidebar.Trigger />, sidebar.slot)
                ) : (
                  <Sidebar.Trigger defaultItem={data.sidebarDefaultItem} />
                )
              ) : sidebar.variant === 'mobile' ? (
                createPortal(<Sidebar.Trigger />, sidebar.slot)
              ) : null}

              {Boolean(data.sidebarConfig?.dialer) && data.isStaffPlus && (
                <Sidebar.Button
                  toggles="dialer"
                  label="Call Someone"
                  icon={faPhone}
                />
              )}

              {/*<SidebarButton tooltipTitle="Information" onClick={() => { setSidebarPanelItem('info'); }}>*/}
              {/*  <FontAwesomeScalableIcon icon={faCircleInfo} />*/}
              {/*</SidebarButton>*/}
              {/*<SidebarButton tooltipTitle="Assessments">*/}
              {/*  <FontAwesomeScalableIcon icon={faClipboard} />*/}
              {/*</SidebarButton>*/}

              {/* TODO: These checks will need to do be updated when implementing groups in TS-13761 */}
              {appointment !== null &&
                Boolean(data.appointmentUsers.length) && (
                  <Sidebar.Button
                    toggles="patientInfo"
                    label="Patient Information"
                    icon={faCircleInfo}
                  />
                )}

              {Boolean(data.sidebarConfig?.notes) && appointment !== null && (
                <Sidebar.Button toggles="notes" label="Notes" icon={faNote} />
              )}

              {Boolean(data.sidebarConfig?.transfer) &&
                isTransferVisitActionEnabled(appointment) && (
                  <Sidebar.Button
                    label="Transfer"
                    icon={faArrowRightArrowLeft}
                    onClick={transferVisitModal.open}
                  />
                )}

              {/*
                `data.appointment` is technically redundant but since
                `data.isBookAgainEnabled` is a boolean the type/value can't be
                inferred properly.
                */}
              {Boolean(data.sidebarConfig?.bookAgain) &&
                data.isBookAgainEnabled &&
                data.isStaffPlus &&
                data.appointment && (
                  <Sidebar.Button
                    label="Book Again"
                    icon={faCalendarPlus}
                    onClick={bookAgainModal.open}
                  />
                )}

              {/*
                We should have an org if the sidebar is enabled but this check
                is just to keep it in sync with the panel condition because we
                need an org to build the default message of the invitation.
                */}
              {data.isStaffPlus && data.org !== null && (
                <Sidebar.Button
                  toggles="invite"
                  label="Invite Someone"
                  icon={faCirclePlus}
                />
              )}

              {Boolean(data.sidebarConfig?.noShow) &&
                data.isNoShowEnabled &&
                data.session.appointmentId && (
                  <Sidebar.Button
                    label="No Show"
                    icon={faBan}
                    onClick={noShowModal.open}
                  />
                )}

              {/*
                Hosts use a different url (HostURL) but we're not enabling it
                as of TS-13702 because we can't share that single url for
                multiple staff+ users.
                */}
              {!data.isStaffPlus && !data.isKioskUser && (
                <Sidebar.Button
                  label="Join via the Zoom app for a more reliable connection"
                  onClick={joinInZoomModal.open}
                  customIcon={<ZoomLogo />}
                />
              )}
            </Sidebar.ButtonList>

            <Sidebar.Panel>
              {({ activeItem, toggleItem }) =>
                activeItem === 'patientInfo' &&
                appointment &&
                data.appointmentUsers.length ? (
                  <InformationPanel
                    appointment={appointment}
                    users={data.appointmentUsers}
                  />
                ) : activeItem === 'dialer' ? (
                  <Dialer
                    onCall={makeCall}
                    speedDials={data.speedDials}
                    callState={
                      typeof snapshot.value === 'string'
                        ? 'inactive'
                        : snapshot.value.active?.callState ?? 'inactive'
                    }
                  />
                ) : activeItem === 'notes' && appointment !== null ? (
                  <Notes
                    appointmentId={appointment.id}
                    note={appointment.notes?.[0]}
                    onAppointmentUpdate={setAppointment}
                  />
                ) : activeItem === 'invite' && data.org !== null ? (
                  <Invite
                    defaultInvitees={
                      /**
                       * TODO: Tech debt - See explanation in the definition of
                       * the `UsersAutocomplete` component.
                       */
                      appointment
                        ? (appointment.patient as unknown as User)
                        : undefined
                    }
                    defaultMessage={`Hello, this is ${data.org.name}${data.org.formattedPhone ? ` at ${data.org.formattedPhone}` : ''}.\nYou are invited to join a video visit currently in progress.`}
                    url={`${window.location.origin}${window.location.pathname}`}
                    getUserUrl={(user) =>
                      user.id === data.appointment?.patientId
                        ? data.appointment.visitUri
                        : undefined
                    }
                    getUserCustomVariables={() => ({
                      sessionCode: data.session.code,
                    })}
                    onSuccess={() => toggleItem('invite')}
                  />
                ) : null
              }
            </Sidebar.Panel>
          </Sidebar.Root>
        )}
      </Grid>

      {snapshot.matches('checkOut') && (
        <CheckOutModal
          appointmentId={Number(data.session.appointmentId)}
          onClose={() => send({ type: 'checkout.action' })}
        />
      )}

      {noShowModal.isOpen && data.session.appointmentId && (
        <NoShowModal
          appointmentId={Number(data.session.appointmentId)}
          onClose={noShowModal.dismiss}
          onSuccess={handleSuccessfulNoShow}
        />
      )}

      {bookAgainModal.isOpen && data.appointment && (
        <BookAgainModal
          isOpen
          appointment={data.appointment}
          onDismiss={bookAgainModal.dismiss}
          onSuccess={bookAgainModal.dismiss}
        />
      )}

      {transferVisitModal.isOpen &&
        isTransferVisitActionEnabled(appointment) && (
          <TransferVisitModal
            isOpen
            onDismiss={transferVisitModal.dismiss}
            onSuccess={handleTransferVisitSuccess}
            appointment={appointment}
            appointmentStatuses={data.appointmentStatuses}
          />
        )}

      <KeepAlive />

      {joinInZoomModal.isOpen && (
        <JoinInZoomModal
          userType={data.isStaffPlus ? 'host' : 'guest'}
          joinUrl={data.joinUrl}
          onClose={joinInZoomModal.dismiss}
        />
      )}
    </>
  );
}

function InvalidLink(props: { error: InvalidLinkError }) {
  useReportToNewRelic(props.error);

  return (
    <RouteError
      illustrationUrl={invalidSessionCodeUrl}
      title="Invalid link"
      message={props.error.message}
    />
  );
}

export function Error() {
  const error = useRouteError();

  if (error instanceof InvalidLinkError) {
    return <InvalidLink error={error} />;
  }

  // Unexpected error, rethrow for it to bubble up to the global error boundary
  throw error;
}
