import type { Appointment } from '@mend/types/appointment';

import * as React from 'react';

import { getAppointment } from '#services/api/appointment';
import useAsync from './async';

/**
 * TODO This is tech-debt, it was brought it as part of TS-13028 for the book
 * again action. This should be replaced by a react query's query.
 */
export default function useAppointment(
  appointmentId: number,
  onAppointmentFetched?: (appointment: Appointment) => void
): { appointment: Appointment | null } {
  const { run, data: appointment } = useAsync<Appointment>();

  React.useEffect(() => {
    void run(
      getAppointment(appointmentId).then((appointment) => {
        onAppointmentFetched?.(appointment);
        return appointment;
      })
    );
  }, [appointmentId, run, onAppointmentFetched]);

  return { appointment: appointment ?? null };
}
