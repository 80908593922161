import type { User } from '@mend/types/user';
import type { CardProps } from '#components/DataTable';

import { Stack } from '@mui/material';

import { Card } from '#components/DataTable';

/*
 * This card is shared in both
 * instances (All Users and Available Providers),
 * we do the `isProviderCell` to differentiate
 * from which instance is the action.
 * */
export default function SharedUsersCard({
  row: { cells },
  getCellRendererProps,
}: CardProps<User>): JSX.Element {
  const actionsCell = cells.find(({ column }) => column.id === 'actions');
  const isProviderCell = actionsCell?.column['Header'] === 'Open Slots';
  const providerCell = cells.find(({ column }) => column.id === 'name');

  return (
    <Card.Root expandable={false}>
      <Card.Content>
        <Stack direction={isProviderCell ? 'column-reverse' : 'row'}>
          {actionsCell?.render('Cell', {
            customProps: getCellRendererProps?.(actionsCell),
          })}
          {providerCell?.render('Cell', {
            customProps: getCellRendererProps?.(providerCell),
          })}
        </Stack>
      </Card.Content>
    </Card.Root>
  );
}
