import type {
  Appointment,
  AppointmentTransferStatus,
  AssignedUser,
} from '@mend/types/appointment';
import type { User } from '@mend/types/user';
import type { IdType, Row } from 'react-table';

import { matchSorter } from '#lib/match-sorter';
import fuzzySearchMultipleWords from '#lib/react-table';

export type CellRendererTransferStatusProps = {
  currentStatusTypeId: Appointment['appointmentStatusId'];
  handleStatusType: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function globalFilterTransferStatus(
  rows: Row<AppointmentTransferStatus>[],
  _columnIds: IdType<AppointmentTransferStatus>[],
  globalFilterValue = ''
): Row<AppointmentTransferStatus>[] {
  return matchSorter(rows, globalFilterValue, {
    keys: ['original.name', 'original.description'],
  });
}

export function globalFilter(
  rows: Row<User>[],
  _columnIds: IdType<User>[],
  globalFilterValue = ''
): Row<User>[] {
  return fuzzySearchMultipleWords(
    rows,
    [
      'original.firstName',
      'original.lastName',
      // 'original.specialtyName',
      // 'original.credentialName',
      'original.id',
      // 'original.email',
      // 'original.mobile',
    ],
    globalFilterValue
  );
}

export type CellRendererAllUsersProps = {
  handleUserSelection: (user: User) => void;
};

export type UserType = 'all' | 'provider';

export type SlotRange = {
  endDate: string;
  startDate: string;
  id?: string;
};

export type CellRendererAvailableProvidersProps = {
  handleSlotSelection: (slotRange: SlotRange) => void;
  handleUserSelection: (user: User) => void;
  selectedSlot: SlotRange;
  timezone: string;
  currentTimezone: string;
  transferUser?: User | AssignedUser | null;
};
