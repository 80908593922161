import type { AssignedUser } from '@mend/types/appointment';
import type { User } from '@mend/types/user';
import type * as React from 'react';

import { config } from '#config';
import { getFullName } from '#utils/user';
import ProviderInfo from '../ProviderInfo';

type NameProps = {
  user: User | AssignedUser;
  children?: React.ReactNode;
};

const isFullUser = (user: User | AssignedUser): user is User =>
  (user as User).credentialName !== undefined &&
  (user as User).specialtyName !== undefined;

export default function Name({ user, children }: NameProps): JSX.Element {
  return (
    <ProviderInfo
      name={getFullName(user)}
      avatarUrl={
        isFullUser(user) && user.thumbnail
          ? `${config.API_BASE_URL}${user.thumbnail}`
          : undefined
      }
      credentials={isFullUser(user) ? user.credentialName : undefined}
      specialty={isFullUser(user) ? user.specialtyName : undefined}
    >
      {children}
    </ProviderInfo>
  );
}
