import type {
  Appointment,
  AppointmentTransferStatus,
  AppointmentType,
  Schedule,
  Symptom,
  Type,
} from '@mend/types/appointment';
import type { User, Ward } from '@mend/types/user';
import type { Response } from '#types/common';

import { api } from '../http-clients';

type AppointmentResponse = Response<{ appointment: Appointment }>;

export function getAppointment(
  appointmentId: number | string
): Promise<Appointment> {
  return api
    .get<AppointmentResponse>(`/appointment/${appointmentId}`)
    .then((res) => res.data.payload.appointment);
}

export type CreateAppointmentParams = {
  appointmentTypeId: number;
  approved: number;
  endDate: string;
  startDate: string;
  patientId: number;
  providerId: number;
  symptoms: Appointment['symptoms'] | Pick<Symptom, 'content'>[];
  type: AppointmentType;
  wards: Ward[] | null;
  recurring?: 1 | 0;
  addressId?: number;
};

export function createAppointment(
  params: CreateAppointmentParams
): Promise<Response> {
  return api.post('/appointment', params);
}

type UpdateAppointmentPayload = {
  checkOutDate?: string;
  appointmentStatusId?: number;
  assignedUserId?: number | null;
  startDate?: string;
  endDate?: string;
  notes?: { content: string; id?: number }[];
  noShow?: 0 | 1;
};

export function updateAppointment(
  appointmentId: number | string,
  payload: UpdateAppointmentPayload
): Promise<Appointment> {
  return api
    .put<AppointmentResponse>(`/appointment/${appointmentId}`, payload)
    .then((res) => res.data.payload.appointment);
}

export function checkOut(
  appointmentId: number
): ReturnType<typeof updateAppointment> {
  return updateAppointment(appointmentId, {
    checkOutDate: new Date().toISOString(),
  });
}

export function confirmAppointmentTransfer(
  appointmentId: number,
  params: {
    appointmentStatusId: number;
    assignedUserId: number | null;
    startDate?: string;
    endDate?: string;
  }
): ReturnType<typeof updateAppointment> {
  return updateAppointment(appointmentId, params);
}

export function noShow(appointmentId: number): Promise<Appointment> {
  return updateAppointment(appointmentId, { noShow: 1 });
}

export function upsertAppointmentNote(
  appointmentId: number | string,
  note: NonNullable<UpdateAppointmentPayload['notes']>[number]
): ReturnType<typeof updateAppointment> {
  return updateAppointment(appointmentId, { notes: [note] });
}

export function getStatusTypes(): Promise<AppointmentTransferStatus[]> {
  return api
    .get<
      Response<{
        appointmentStatuses: AppointmentTransferStatus[];
      }>
    >(`/appointment-status`)
    .then((res) => res.data.payload.appointmentStatuses);
}

type ProviderScheduleParams = Pick<
  Appointment,
  'canceled' | 'deleted' | 'providerId' | 'startDate' | 'endDate'
> & {
  excludeTypes: string;
  limit: number;
};

type GetProviderScheduleResponse = {
  schedule: Schedule[];
};

export function getProviderSchedule(
  params: ProviderScheduleParams
): Promise<Schedule[]> {
  return api
    .get<Response<GetProviderScheduleResponse>>('/appointment/schedule', {
      params,
    })
    .then((res) => res.data.payload.schedule);
}

type GetAppointmentTypeResponse = {
  appointmentTypes: AppointmentType[];
};

/**
 * Compared to `/appointment-type/type/all` (without a type passed here), this
 * endpoint does NOT return the types where `visible = 0`.
 */
export function getAppointmentTypes(type?: Type): Promise<AppointmentType[]> {
  return api
    .get<
      Response<GetAppointmentTypeResponse>
    >(`/appointment-type${type ? `/${type}` : ''}`)
    .then((res) => res.data.payload.appointmentTypes);
}

export function getGroupUsers(appointmentId: number): Promise<User[]> {
  return api
    .get<
      Response<{ users: User[] }>
    >(`/appointment/${appointmentId}/group/get-users`)
    .then((res) => res.data.payload.users);
}
