import type { Org } from '@mend/types/org';
import type { TypographyProps } from '@mui/material';
import type { LayoutOutletContext } from './Layout.types';

import * as React from 'react';
import { styled } from '@mend/mui';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  Outlet,
  useMatches,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';

import mendLogoUrl from '#assets/images/mend-logo.png';
import TechSupport from '#components/TechSupport/TechSupport';
import { config } from '#config';
import useReportToNewRelic from '#hooks/report-to-new-relic.ts';
import { me } from '#lib/react-query/queries';
import { getCookie } from '#utils/cookies';
import { roleGreaterOrEquals } from '#utils/roles';
import GenericRouteError from '../GenericRouteError';

const Grid = styled('div')({
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content 1fr',
});

const Nav = styled('nav')(({ theme }) => ({
  height: '90px',
  padding: theme.spacing(2),
  gridColumn: '1 / -1',
  alignSelf: 'center',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr) min-content',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyItems: 'center',
  borderBottom: `1px solid ${theme.palette.navbar.main}`,
  backgroundColor: theme.palette.navbar.main,
  color: theme.palette.navbar.contrastText,

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

const LogoWrapper = styled(Box)({
  justifySelf: 'start',
}) as typeof Box;

const Logo = styled('img', {
  shouldForwardProp: (prop) => prop !== 'isCustom',
})<{ isCustom: boolean }>(({ isCustom }) => ({
  width: 'auto',
  height: isCustom ? '58px' : '33px',
  display: 'block',
}));

const Title = styled((props: TypographyProps) => (
  <Typography component="h1" variant="h6" color="inherit" {...props} />
))(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

const TechSupportWrapper = styled('div')({
  justifySelf: 'end',
});

const VideoSidebarSlotWrapper = styled('div')(({ theme }) => ({
  display: 'block',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

/**
 * Get the title from the route handle (if any) and update the document title.
 */
function useRouteTitle() {
  const matches = useMatches();
  const lastMatchHandle = matches.at(-1)?.handle;
  const title =
    typeof lastMatchHandle === 'object' &&
    lastMatchHandle &&
    'title' in lastMatchHandle &&
    typeof lastMatchHandle.title === 'string'
      ? lastMatchHandle.title
      : 'Mend';

  React.useEffect(() => {
    document.title = title === 'Mend' ? 'Mend' : `Mend - ${title}`;
  }, [title]);

  return title;
}

/**
 * TODO
 * In the future, we will allow support for patients the be identified with pendo
 */
function useInitPendo() {
  const { data: { user, org } = {} } = useQuery(me);
  const isInitializedRef = React.useRef(false);

  React.useEffect(() => {
    if (isInitializedRef.current || !roleGreaterOrEquals(user?.role, 'Staff')) {
      return;
    }

    const visitor = {
      id: user?.id || null,
      userId: user?.id || null,
      role: user?.role || 'Guest',
      orgId: org?.id,
    };

    const account = {
      id: org?.id || null,
      userId: user?.id || null,
      role: user?.role || 'Guest',
      orgId: org?.id,
      orgName: org?.name || 'None Set',
    };

    window.pendo?.initialize({ visitor, account });
    isInitializedRef.current = true;
  }, [user, org]);
}

function useLogo(org?: Org): {
  isCustom: boolean;
  alt: string;
  src: string;
  onError: () => void;
} {
  const [logoPathInCookie] = React.useState(() => {
    const value = getCookie('last-logo', '');
    return value.startsWith('/file/') ? value : '';
  });

  /**
   * Initialize the status based on the logo path in the cookie or the org
   * settings.
   */
  const [status, setStatus] = React.useState<'mend' | 'custom' | 'error'>(() =>
    logoPathInCookie !== '' || Boolean(org?.settings.logoUri)
      ? 'custom'
      : 'mend'
  );

  /**
   * If the status is mend, it means we didn't have a logo path in the cookie
   * nor in the org settings during the initial render, but if that was the
   * case and we have an org logo now, we should update the status to custom.
   */
  if (status === 'mend' && org?.settings.logoUri) {
    setStatus('custom');
  }

  function onError() {
    if (status === 'custom') {
      console.warn('Failed to load org logo, falling back to Mend logo.');
      setStatus('error');
    }
  }

  if (status === 'custom') {
    const alt = org ? `${org.name} logo` : 'Company logo';
    const src = org
      ? `${config.API_BASE_URL}/file/${org.settings.logoUri}`
      : `${config.API_BASE_URL}${logoPathInCookie}`;
    return { isCustom: true, alt, src, onError };
  }

  return { isCustom: false, alt: 'Mend logo', src: mendLogoUrl, onError };
}

type BaseLayoutProps = {
  videoSidebarSlotRef?: (ref: HTMLElement | null) => void;
  children: React.ReactNode;
};

function BaseLayout(props: BaseLayoutProps) {
  const title = useRouteTitle();
  const meQuery = useQuery(me);
  const logo = useLogo(meQuery.data?.org);

  const [searchParams] = useSearchParams();
  const isOpenedInPip = searchParams.get('isOpenedInPip') === 'true';
  const isStaffPlus = roleGreaterOrEquals(meQuery.data?.user?.role, 'Staff');

  const canLogoRedirect = isStaffPlus && !isOpenedInPip;

  return (
    <Grid>
      <Nav>
        <LogoWrapper
          component={canLogoRedirect ? 'a' : 'div'}
          href={canLogoRedirect ? config.PORTAL_BASE_URL : undefined}
        >
          <Logo {...logo} />
        </LogoWrapper>

        <Title>{title}</Title>

        <TechSupportWrapper>
          <TechSupport />
        </TechSupportWrapper>

        <VideoSidebarSlotWrapper>
          <div id="video-sidebar-slot" ref={props.videoSidebarSlotRef} />
        </VideoSidebarSlotWrapper>
      </Nav>

      {props.children}
    </Grid>
  );
}

export function Layout() {
  useInitPendo();

  /**
   * Store the video sidebar slot element in a state so it triggers a re-render
   * and can be provided to the outlet context.
   */
  const [videoSidebarSlot, setVideoSidebarSlot] =
    React.useState<HTMLElement | null>(null);

  return (
    <BaseLayout videoSidebarSlotRef={setVideoSidebarSlot}>
      <Outlet context={{ videoSidebarSlot } satisfies LayoutOutletContext} />
    </BaseLayout>
  );
}

/**
 * The error element/boundary replaces the route's element completely, so if we
 * don't reuse the layout here (as the root/upper-most error element/boundary),
 * we'd lose the navbar.
 */
export function Error() {
  const error = useRouteError();

  useReportToNewRelic(error);

  return (
    <BaseLayout>
      <GenericRouteError />
    </BaseLayout>
  );
}
