import type { Appointment } from '@mend/types/appointment';

import * as React from 'react';

type SymptomsState = {
  symptoms: string;
  onSymptomsChange: (value: string) => void;
  initFromAppointment: (appointment: Appointment) => void;
  initialSymptoms: string;
};

/**
 * TODO This is tech-debt, it was brought it as part of TS-13028 for the book
 * again action. This should be replaced by a react query's query.
 */
export default function useSymptoms(): SymptomsState {
  /**
   * Flag used to compare the initial symptoms with the current ones for the
   * confirmation modal.
   */
  const initialSymptomsRef = React.useRef('');
  const [symptoms, setSymptoms] = React.useState('');

  const initFromAppointment = React.useCallback((appointment: Appointment) => {
    const symptoms =
      !Array.isArray(appointment.symptoms) || appointment.symptoms.length === 0
        ? ''
        : appointment.symptoms.map(({ content }) => content).join('\n');
    initialSymptomsRef.current = symptoms;
    setSymptoms(symptoms);
  }, []);

  return {
    symptoms,
    onSymptomsChange: setSymptoms,
    initFromAppointment,
    initialSymptoms: initialSymptomsRef.current,
  };
}
