import { styled } from '@mend/mui';
import { Typography } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  maxWidth: '420px',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}));

const Illustration = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  marginBottom: theme.spacing(4),
}));

type RouteErrorProps = {
  illustrationUrl?: string;
  title?: string;
  /**
   * Message to be displayed. If a `React.Element` is provided, make sure to
   * pass valid elements that can be rendered within a `p` tag.
   */
  message?: React.ReactNode;
};

const defaultTitle = 'Uh oh! Something went wrong';
const defaultMessage = 'Please try again.';

export default function RouteError({
  illustrationUrl,
  title = defaultTitle,
  message = defaultMessage,
}: RouteErrorProps) {
  return (
    <Container>
      {illustrationUrl && <Illustration src={illustrationUrl} alt="" />}
      <Typography component="h2" variant="h5">
        {title}
      </Typography>
      <Typography variant="body1" marginTop={2}>
        {message}
      </Typography>
    </Container>
  );
}
