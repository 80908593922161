import type { User } from '@mend/types/user';
import type { StatisticsData } from '../../../zoom/qos-logger';
import type { LoaderData } from './Session.data';

import { addDays } from 'date-fns';
import { z } from 'zod';

import { getCookie, removeCookie, setCookie } from '#utils/cookies';
import { roleGreaterOrEquals } from '#utils/roles.ts';

const IDENTIFIER_COOKIE = 'unique-user-id';

export class InvalidLinkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'InvalidLinkError';
  }
}

/**
 * Generate a unique identifier and store it in a cookie
 */
function generateUniqueIdentifier(): string {
  const id = `A${getRandomInt(100_000, 999_999)}`;
  setCookie(IDENTIFIER_COOKIE, id, addDays(new Date(), 30));
  return id;
}

function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Reuse the unique identifier if it exists, otherwise generate a new one
 */
export function getUniqueIdentifier(): string {
  return getCookie(IDENTIFIER_COOKIE) ?? generateUniqueIdentifier();
}

/**
 * Remove the unique identifier cookie
 */
export function removeUniqueIdentifier(): void {
  removeCookie(IDENTIFIER_COOKIE);
}

/**
 * We use a union instead of `number`/`refine` so the output type contains
 * the exact list of numbers.
 */
const zoomNetworkQualityLevelSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
]);

export type ZoomNetworkQualityLevel = z.output<
  typeof zoomNetworkQualityLevelSchema
>;

const visitStatisticsMessageSchema = z.object({
  type: z.literal('visit.statistics'),
  uuid: z.string(),
  /**
   * The statistics are processed in the child iframe and sent to the parent,
   * since we're trusting zoom with the data, and we only send it as is, we
   * avoid validating the full schema here. The `as StatisticsData` helps us
   * avoid having to cast the value in the consumer.
   */
  statistics: z
    .object({})
    .passthrough()
    .transform((value) => value as StatisticsData),
});

const zoomErrorMessageSchema = z.object({
  type: z.literal('zoom.error'),
  name: z.string(),
  code: z.number().optional(),
  data: z.string(),
  uuid: z.string(),
});

/**
 * Schema for messages received from the iframe
 */
export const messageSchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('visit.init.error') }),
  z.object({ type: z.literal('visit.screen.video'), active: z.boolean() }),
  z.object({ type: z.literal('visit.ended'), force: z.boolean().optional() }),
  z.object({
    type: z.literal('visit.networkQualityChanged'),
    level: zoomNetworkQualityLevelSchema,
  }),
  visitStatisticsMessageSchema,
  zoomErrorMessageSchema,
  z.object({ type: z.literal('zoom.callout.error') }),
]);

function getZoomDataSharedPayload(loaderData: LoaderData) {
  return {
    vendorSessionId: loaderData.meetingNumber,
    user_data: {
      userId: loaderData.customerKey,
      userName: loaderData.userName,
      orgId: String(loaderData.session.orgId),
      videoCode: loaderData.sessionId,
      appointmentId: String(loaderData.session.appointmentId),
    },
  };
}

export function getZoomStatisticsDataPayload({
  loaderData,
  eventData,
}: {
  loaderData: LoaderData;
  eventData: z.infer<typeof visitStatisticsMessageSchema>;
}) {
  return {
    ...getZoomDataSharedPayload(loaderData),
    sessionUniqueId: eventData.uuid,
    audio: eventData.statistics.audio,
    video: eventData.statistics.video,
    other: Object.entries(eventData.statistics.screenshare).reduce(
      (acc, [key, value]) => {
        acc[`screenshare_${key}`] = value;
        return acc;
      },
      {} as Record<string, number>
    ),
    timestamp: Date.now(),
  };
}

export function getZoomErrorDataPayload({
  loaderData,
  eventData,
}: {
  loaderData: LoaderData;
  eventData: z.infer<typeof zoomErrorMessageSchema>;
}) {
  return {
    ...getZoomDataSharedPayload(loaderData),
    sessionUniqueId: eventData.uuid,
    error: {
      ...(eventData.code ? { code: eventData.code } : {}),
      name: eventData.name,
      data: eventData.data,
    },
  };
}

export type SidebarConfig = Record<
  'dialer' | 'notes' | 'transfer' | 'noShow' | 'bookAgain',
  boolean
>;

export function getSearchParamFromJoinUrl(
  joinUrl: string,
  searchParamKey: 'pwd' | 'tk'
) {
  const url = new URL(joinUrl);
  const value = url.searchParams.get(searchParamKey);
  if (!value) {
    throw new Error(`Missing ${searchParamKey} search param in join url`);
  }
  return value;
}

export function getUserDisplayName(
  isGroupAppointment: boolean,
  user?: User | undefined
) {
  if (!user) {
    return 'Unknown Participant';
  }

  const isStaffPlus = roleGreaterOrEquals(user.role, 'Staff');
  const firstName = String(user.firstName).trim();
  const lastName = String(user.lastName).trim();
  if (isStaffPlus || !isGroupAppointment) {
    return `${firstName} ${lastName}`.trim();
  }

  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  return `${firstInitial} ${lastInitial}`.trim();
}
