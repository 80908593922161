import type { Theme } from '@mend/mui';
import type { PaletteColor, ThemeOptions } from '@mui/material/styles';

import * as React from 'react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { baseThemeOptions, lightThemeOptions } from '@mend/mui';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { properties } from '#lib/react-query/queries';
import { useOrgIdStore } from '#stores/org-id.ts';
import { BRANDING_CONFIG } from '#utils/properties';

/**
 * This project uses a non-standard color called `navbar` so we need to use
 * module augmentation to make it available in the theme.
 */
declare module '@mui/material/styles' {
  interface Palette {
    navbar: Palette['primary'];
  }

  interface PaletteOptions {
    navbar?: PaletteOptions['primary'];
  }
}

const BRANDING_FONT_FAMILY_URL_ID = 'branding-font-family-url';

function maybeTrimString<TDefault>(
  value: unknown,
  defaultValue: TDefault
): string | TDefault {
  return typeof value === 'string' ? value.trim() : defaultValue;
}

const COLOR_REGEX = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

const colorSchema = z
  .string()
  .nullable()
  .default(null)
  .catch(null)
  .transform((value) => (COLOR_REGEX.test(value ?? '') ? value : null));

const fontFamilyValueSchema = z
  .string()
  .nullable()
  .default(null)
  .catch(null)
  .transform((value) => {
    let result = maybeTrimString(value, null);
    if (typeof result === 'string') {
      // Trim trailing semi-colon(s)
      result = result.replace(/;+$/, '');
    }
    return result;
  });

const fontFamilyUrlSchema = z
  .string()
  .nullable()
  .default(null)
  .catch(null)
  .transform((value) => {
    const result = sanitizeUrl(maybeTrimString(value, undefined));
    return result === 'about:blank' ? null : result;
  });

const themeOverridesSchema = z
  .object({
    primaryColor: colorSchema,
    secondaryColor: colorSchema,
    navbarColor: colorSchema,
    fontFamilyValue: fontFamilyValueSchema,
    fontFamilyUrl: fontFamilyUrlSchema,
  })
  .transform((data) => {
    /**
     * If no navbar color is provided but primary color, use it. This prevents
     * needing to check if the navbar color is set when reading its value.
     */
    if (!data.navbarColor && data.primaryColor) {
      data.navbarColor = data.primaryColor;
    }

    return data;
  });

function getThemeOverridesFromConfig(
  config: z.output<typeof themeOverridesSchema>
): ThemeOptions {
  return {
    ...((config.primaryColor ||
      config.secondaryColor ||
      config.navbarColor) && {
      palette: {
        ...(config.primaryColor && {
          primary: { main: config.primaryColor },
        }),
        ...(config.secondaryColor && {
          secondary: { main: config.secondaryColor },
        }),
        ...(config.navbarColor && {
          navbar: { main: config.navbarColor },
        }),
      },
    }),
    ...(config.fontFamilyValue && {
      typography: {
        fontFamily: config.fontFamilyValue,
        h1: { fontFamily: config.fontFamilyValue },
        h2: { fontFamily: config.fontFamilyValue },
        h3: { fontFamily: config.fontFamilyValue },
        h4: { fontFamily: config.fontFamilyValue },
        h5: { fontFamily: config.fontFamilyValue },
        h6: { fontFamily: config.fontFamilyValue },
        subtitle1: { fontFamily: config.fontFamilyValue },
        subtitle2: { fontFamily: config.fontFamilyValue },
        body1: { fontFamily: config.fontFamilyValue },
        body2: { fontFamily: config.fontFamilyValue },
        button: { fontFamily: config.fontFamilyValue },
        caption: { fontFamily: config.fontFamilyValue },
        overline: { fontFamily: config.fontFamilyValue },
      },
    }),
  };
}

export default function useCustomBranding(): Theme {
  const orgId = useOrgIdStore((state) => state.orgId);
  const { data, status } = useQuery(properties(orgId));

  const rawConfig = data?.[BRANDING_CONFIG];

  const config = React.useMemo(() => {
    let config: string | null = null;

    /**
     * If the status is pending, it means that either the properties are being
     * loaded or the query is disabled. If the status is error, it means that
     * the query has failed, potentially due to an expired access token. In
     * both cases, we should use the last saved config from local storage.
     */
    if (status === 'pending' || status === 'error') {
      try {
        config = window.localStorage.getItem('last-custom-branding');
      } catch (error) {
        // Do nothing
      }
    } else {
      /**
       * The query is enabled and has succeeded, so we should use the property
       * value (even if nothing is present) and ignore what's in local storage.
       */
      config = typeof rawConfig === 'string' ? rawConfig : null;
    }

    if (!config) return null;

    let json: unknown = {};

    try {
      json = JSON.parse(config);
    } catch (error) {
      // Do nothing
    }

    const result = themeOverridesSchema.safeParse(json);
    return result.success ? result.data : null;
  }, [status, rawConfig]);

  /**
   * Load the font family from google fonts.
   */
  React.useEffect(() => {
    if (!config || !config.fontFamilyUrl) return;

    const style = document.createElement('style');
    style.id = BRANDING_FONT_FAMILY_URL_ID;
    style.innerHTML = `@import url('${config.fontFamilyUrl}');`;
    document.head.appendChild(style);

    return () => style.remove();
  }, [config]);

  return React.useMemo(() => {
    /**
     * Since this project doesn't support a dark mode, we can safely merge
     * the base theme options with the light theme options.
     */
    let themeOptions = deepmerge(
      deepmerge(baseThemeOptions, lightThemeOptions),
      {
        palette: {
          navbar: {
            // Use the primary color as the default navbar color
            main: (lightThemeOptions.palette?.primary as PaletteColor).main,
          },
        },
      }
    );

    if (
      config?.primaryColor ||
      config?.secondaryColor ||
      config?.navbarColor ||
      config?.fontFamilyValue
    ) {
      themeOptions = deepmerge(
        themeOptions,
        getThemeOverridesFromConfig(config)
      );
    }

    const theme = createTheme(themeOptions);

    /**
     * The theme above gets created with the base theme _options_ which don't
     * have the augmented color for the navbar (we need a theme in place to
     * make use of such utility), so we always need to re-calculate it here
     * even if a custom color isn't provided.
     *
     * @see https://mui.com/material-ui/customization/palette/#custom-colors
     * @see https://mui.com/material-ui/customization/palette/#generate-tokens-using-augmentcolor-utility
     */
    return createTheme(theme, {
      palette: {
        navbar: theme.palette.augmentColor({
          color: { main: theme.palette.navbar.main },
          name: 'navbar',
        }),
      },
    });
  }, [config]);
}
