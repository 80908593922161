import type { Ward } from '@mend/types/user';
import type { CardField, CardProps } from '../../DataTable';

import { Card } from '../../DataTable';

const list: CardField<Ward>[] = [
  { id: 'name' },
  { id: 'birthDate' },
  { id: 'gender' },
];

export default function DependentCard({
  row: { cells },
  getCellRendererProps,
}: CardProps<Ward>): JSX.Element {
  return (
    <Card.Root>
      <Card.Content>
        <Card.FieldsList
          list={list}
          cells={cells}
          getCellRendererProps={getCellRendererProps}
        />
      </Card.Content>
      <Card.Footer />
    </Card.Root>
  );
}
