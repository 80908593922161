// src/theme.base.ts
import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
var poppinsFontFamily = ['"Poppins"', "serif"].join(",");
var interFontFamily = ['"Inter"', "serif"].join(",");
var avatarOverrides = {
  width: 32,
  height: 32,
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: 1.25,
  letterSpacing: "0.03em"
};
var themeOptions = {
  spacing: 8,
  shape: { borderRadius: 4 },
  palette: {
    /**
     * `mui` doesn't automatically generate the missing tokens based on the
     * `main` color for custom colors, so we need to define them all explicitly.
     *
     * @see https://v5.mui.com/material-ui/customization/palette/#custom-colors
     */
    tertiary: {
      light: "#ef874d",
      main: "#ec6a21",
      dark: "#a54a17",
      contrastText: "#ffffff"
    },
    // Same as `palette.tertiary`
    quaternary: {
      light: "#788edc",
      main: "#5772d4",
      dark: "#3c4f94",
      contrastText: "#ffffff"
    }
  },
  typography: {
    fontFamily: interFontFamily,
    h1: {
      fontFamily: poppinsFontFamily,
      fontSize: "6rem",
      fontWeight: 500,
      lineHeight: 1.167,
      letterSpacing: "0.03em"
    },
    h2: {
      fontFamily: poppinsFontFamily,
      fontSize: "3.75rem",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0.03em"
    },
    h3: {
      fontFamily: poppinsFontFamily,
      fontSize: "3rem",
      fontWeight: 500,
      lineHeight: 1.17,
      letterSpacing: "0.03em"
    },
    h4: {
      fontFamily: poppinsFontFamily,
      fontSize: "2.125rem",
      fontWeight: 500,
      lineHeight: 1.235,
      letterSpacing: "0.03em"
    },
    h5: {
      fontFamily: poppinsFontFamily,
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.334,
      letterSpacing: "0.03em"
    },
    h6: {
      fontFamily: poppinsFontFamily,
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.5,
      letterSpacing: "0.03em"
    },
    subtitle1: {
      fontFamily: interFontFamily,
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: "0.03em"
    },
    subtitle2: {
      fontFamily: interFontFamily,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.45,
      letterSpacing: "0.03em"
    },
    body1: {
      fontFamily: interFontFamily,
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: "0.03em"
    },
    body2: {
      fontFamily: interFontFamily,
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: "0.03em"
    },
    button: {
      fontFamily: interFontFamily,
      fontWeight: 500,
      textTransform: "capitalize",
      letterSpacing: "0.03em"
    },
    caption: {
      fontFamily: interFontFamily,
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: "0.03em"
    },
    overline: {
      fontFamily: interFontFamily,
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 2.5,
      letterSpacing: "0.06em"
    }
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: (props) => ({
          ...avatarOverrides,
          backgroundColor: props.theme.palette.primary.main,
          color: props.theme.palette.primary.contrastText
        })
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: (props) => ({
          ...avatarOverrides,
          backgroundColor: props.theme.palette.primary.main,
          color: props.theme.palette.primary.contrastText
        })
      }
    },
    MuiAlert: {
      defaultProps: {
        variant: "filled"
      },
      styleOverrides: {
        // Copies the body2 typography variant
        message: {
          fontSize: "0.875rem",
          fontWeight: 400,
          lineHeight: 1.43,
          letterSpacing: "0.03em"
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          color: "inherit"
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: 600,
          lineHeight: 1.25
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: "small" },
          style: {
            fontSize: "0.8125rem",
            lineHeight: 1.375
          }
        },
        {
          props: { size: "medium" },
          style: {
            fontSize: "0.875rem",
            lineHeight: 1.5
          }
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "0.9375rem",
            lineHeight: 1.625
          }
        },
        {
          props: { size: "extra-large" },
          style: {
            padding: "12px 32px",
            fontSize: "1.125rem",
            lineHeight: 1.75
          }
        },
        {
          props: { size: "extra-large", variant: "outlined" },
          style: {
            padding: "11px 31px"
            // To account for border in this variant
          }
        }
      ],
      defaultProps: {
        variant: "contained",
        color: "primary"
      }
    },
    MuiCard: {
      styleOverrides: {
        root: (props) => ({
          borderRadius: props.theme.shape.borderRadius * 2.5
          // 10px
        })
      }
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small"
      }
    },
    MuiChip: {
      styleOverrides: {
        label: {
          lineHeight: 1.125,
          letterSpacing: "0.03em"
        },
        icon: {
          width: "0.8em",
          height: "0.8em"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          lineHeight: 1.25
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: (props) => ({
          color: props.theme.palette.error.main
        })
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          letterSpacing: "0.03em"
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          letterSpacing: "0.03em"
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: (props) => ({
          color: props.theme.palette.info.main
        })
      }
    },
    MuiRadio: {
      defaultProps: {
        size: "small"
      }
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
        spacing: 1
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12
        }
      }
    },
    MuiTablePagination: {
      defaultProps: {
        showFirstButton: true,
        showLastButton: true
      }
    },
    MuiTab: {
      styleOverrides: {
        iconWrapper: {
          width: "0.9em",
          height: "0.9em"
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: "small"
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: (props) => ({
          color: props.theme.palette.text.primary
        })
      }
    }
  }
};
function getThemeOptions(options = {}) {
  if (!options.portalContainer) {
    return themeOptions;
  }
  return deepmerge(themeOptions, {
    components: {
      MuiPopover: {
        defaultProps: {
          container: options.portalContainer
        }
      },
      MuiPopper: {
        defaultProps: {
          container: options.portalContainer
        }
      },
      MuiModal: {
        defaultProps: {
          container: options.portalContainer
        }
      }
    }
  });
}
var theme = createTheme(themeOptions);

// src/theme.dark.ts
import { alpha } from "@mui/material/styles";
var themeOptions2 = {
  palette: {
    mode: "dark",
    primary: { main: "#2ba3c3" },
    secondary: { main: "#d81f5b" },
    success: { main: "#57d489" },
    error: { main: "#ff5757" },
    warning: { main: "#f9da6e" },
    info: { main: "#5cecfe" },
    neutral: {
      light: "#d7dadd",
      main: "#cdd1d5",
      dark: "#8f9295",
      contrastText: "#313131"
    },
    muted: {
      1: "#acb3b9",
      2: "#dde1e3",
      3: "#f0f0f0",
      4: "#d7dfe9",
      5: "#eceff3",
      6: "#f0f2f6"
    },
    background: {
      paper: "#052d3d",
      default: "#012331"
    },
    text: {
      primary: "#fbfbfb",
      secondary: "#a2b9c2",
      disabled: "#a0a0a0e6"
    }
  },
  components: {
    MuiAlert: {
      variants: [
        {
          props: { variant: "standard", severity: "error" },
          style: { backgroundColor: "#591817", color: "#ffbcbc" }
        },
        {
          props: { variant: "filled", severity: "error" },
          style: (props) => ({
            backgroundColor: props.theme.palette.error.main,
            color: props.theme.palette.error.contrastText
          })
        },
        {
          props: { variant: "outlined", severity: "error" },
          style: (props) => ({
            borderColor: props.theme.palette.error.main,
            color: "#ffbcbc"
          })
        },
        {
          props: { variant: "standard", severity: "warning" },
          style: { backgroundColor: "#5d4719", color: "#fdf0c5" }
        },
        {
          props: { variant: "filled", severity: "warning" },
          style: (props) => ({
            backgroundColor: props.theme.palette.warning.main,
            color: props.theme.palette.warning.contrastText
          })
        },
        {
          props: { variant: "outlined", severity: "warning" },
          style: (props) => ({
            borderColor: props.theme.palette.warning.main,
            color: "#fdf0c5"
          })
        },
        {
          props: { variant: "standard", severity: "info" },
          style: { backgroundColor: "#1c4b6a", color: "#bef7ff" }
        },
        {
          props: { variant: "filled", severity: "info" },
          style: (props) => ({
            backgroundColor: props.theme.palette.info.main,
            color: props.theme.palette.info.contrastText
          })
        },
        {
          props: { variant: "outlined", severity: "info" },
          style: (props) => ({
            borderColor: props.theme.palette.info.main,
            color: "#bef7ff"
          })
        },
        {
          props: { variant: "standard", severity: "success" },
          style: { backgroundColor: "#1a5432", color: "#bceed0" }
        },
        {
          props: { variant: "filled", severity: "success" },
          style: (props) => ({
            backgroundColor: props.theme.palette.success.main,
            color: props.theme.palette.success.contrastText
          })
        },
        {
          props: { variant: "outlined", severity: "success" },
          style: (props) => ({
            borderColor: props.theme.palette.success.main,
            color: "#bceed0"
          })
        }
      ]
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: (props) => ({
          color: alpha(props.theme.palette.common.white, 0.56)
        })
      }
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: (props) => ({
          padding: props.theme.spacing(0.5, 1),
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: 1.16,
          letterSpacing: "0.03em",
          backgroundColor: props.theme.palette.muted[2],
          color: props.theme.palette.common.black
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: (props) => ({
          padding: props.theme.spacing(0.5, 1),
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: 1.16,
          letterSpacing: "0.03em",
          backgroundColor: "#3a6982",
          color: props.theme.palette.common.white
        }),
        arrow: {
          color: "#3a6982",
          borderColor: "#3a6982"
        }
      }
    }
  }
};

// src/theme.light.ts
var themeOptions3 = {
  palette: {
    mode: "light",
    primary: { main: "#166280" },
    secondary: { main: "#ec2663" },
    success: { main: "#2f7e4f" },
    error: { main: "#db3b21" },
    warning: { main: "#8e7106" },
    info: { main: "#0044cc" },
    neutral: {
      light: "#8c8f92",
      main: "#707477",
      dark: "#4e5153",
      contrastText: "#ffffffe6"
    },
    muted: {
      1: "#363b3f",
      2: "#515659",
      3: "#f0f0f0",
      4: "#31404e",
      5: "#4e5c68",
      6: "#65707c"
    },
    text: {
      primary: "#012331",
      secondary: "#577885",
      disabled: "#676767e6"
    },
    background: {
      default: "#eaecee",
      paper: "#ffffff"
    }
  },
  components: {
    MuiAlert: {
      variants: [
        {
          props: { variant: "standard", severity: "error" },
          style: { backgroundColor: "#fbebe9", color: "#58180d" }
        },
        {
          props: { variant: "outlined", severity: "error" },
          style: (props) => ({
            borderColor: props.theme.palette.error.main,
            color: "#58180d"
          })
        },
        {
          props: { variant: "standard", severity: "warning" },
          style: { backgroundColor: "#f6f0d8", color: "#392d02" }
        },
        {
          props: { variant: "outlined", severity: "warning" },
          style: (props) => ({
            borderColor: props.theme.palette.warning.main,
            color: "#392d02"
          })
        },
        {
          props: { variant: "standard", severity: "info" },
          style: { backgroundColor: "#e9f5fe", color: "#392d02" }
        },
        {
          props: { variant: "outlined", severity: "info" },
          style: (props) => ({
            borderColor: props.theme.palette.info.main,
            color: "#392d02"
          })
        },
        {
          props: { variant: "standard", severity: "success" },
          style: { backgroundColor: "#edf7ed", color: "#1e4620" }
        },
        {
          props: { variant: "outlined", severity: "success" },
          style: (props) => ({
            borderColor: props.theme.palette.success.main,
            color: "#1e4620"
          })
        }
      ]
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: (props) => ({
          padding: props.theme.spacing(0.5, 1),
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: 1.16,
          letterSpacing: "0.03em",
          backgroundColor: props.theme.palette.muted[2],
          color: props.theme.palette.common.white
        })
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: (props) => ({
          padding: props.theme.spacing(0.5, 1),
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: 1.16,
          letterSpacing: "0.03em",
          backgroundColor: props.theme.palette.muted[2],
          color: props.theme.palette.common.white
        }),
        arrow: (props) => ({
          color: props.theme.palette.muted[2],
          borderColor: props.theme.palette.muted[2]
        })
      }
    }
  }
};

// src/styled.ts
import { createStyled } from "@mui/system";
var styled = createStyled({ defaultTheme: theme });

// src/components/FontAwesomeScalableIcon/FontAwesomeScalableIcon.tsx
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SvgIcon } from "@mui/material";
import { jsx } from "react/jsx-runtime";
function FontAwesomeScalableIcon({
  icon,
  ...props
}) {
  return /* @__PURE__ */ jsx(SvgIcon, { ...props, children: /* @__PURE__ */ jsx(
    "svg",
    {
      width: "24",
      height: "24",
      viewBox: "0 0 24 20",
      preserveAspectRatio: "xMidYMid meet",
      children: /* @__PURE__ */ jsx(FontAwesomeIcon, { icon })
    }
  ) });
}

// src/components/IconButton/IconButton.tsx
import MuiIconButton from "@mui/material/IconButton";
import { jsx as jsx2 } from "react/jsx-runtime";
var IconButton = styled(
  (props) => /* @__PURE__ */ jsx2(MuiIconButton, { color: "neutral", ...props }),
  { shouldForwardProp: (prop) => prop !== "variant" }
)(({ theme: theme2, color = "neutral", size = "medium", variant = "standard" }) => {
  const side = size === "small" ? 28 : size === "medium" ? 32 : 40;
  return {
    /**
     * Changing the default padding causes odd UI issues when using FA
     * icons, so we set an explicit width and height instead.
     */
    width: side,
    height: side,
    fontSize: size === "small" ? "1.125rem" : size === "medium" ? "1.25rem" : "1.5rem",
    transition: theme2.transitions.create(
      ["background-color", "box-shadow", "color", "border-color"],
      { duration: theme2.transitions.duration.short }
    ),
    "&.Mui-disabled": {
      color: theme2.palette.action.disabled
    },
    ...variant === "outlined" ? {
      border: `1px solid ${color !== "inherit" ? theme2.palette[color].main : "currentColor"}`,
      "&.Mui-disabled": {
        borderColor: theme2.palette.action.disabledBackground
      }
    } : {},
    ...variant === "contained" && color !== "inherit" ? {
      backgroundColor: theme2.palette[color].main,
      color: theme2.palette[color].contrastText,
      boxShadow: theme2.shadows[2],
      "&:hover, &:focus": {
        backgroundColor: theme2.palette[color].dark,
        boxShadow: theme2.shadows[4]
      },
      "&.Mui-disabled": {
        backgroundColor: theme2.palette.action.disabledBackground
      }
    } : {}
  };
});
var IconButton_default = IconButton;

// src/components/Switch/Switch.tsx
import { Switch } from "@mui/material";
import { jsx as jsx3 } from "react/jsx-runtime";
var Toggle = styled((props) => /* @__PURE__ */ jsx3(
  Switch,
  {
    focusVisibleClassName: ".Mui-focusVisible",
    disableRipple: false,
    ...props
  }
))(({ theme: theme2, color, size }) => ({
  width: size === "small" ? 34 : 42,
  height: size === "small" ? 20 : 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: `translateX(${size === "small" ? 14 : 16}px)`,
      color: theme2.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme2.palette[color ?? "primary"].main,
      border: `6px solid ${theme2.palette.common.white}`
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme2.palette.muted[3]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.5
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: size === "small" ? 16 : 22,
    height: size === "small" ? 16 : 22,
    backgroundColor: theme2.palette.common.white
  },
  "& .MuiSwitch-track": {
    borderRadius: (size === "small" ? 20 : 26) / 2,
    backgroundColor: theme2.palette.neutral.main,
    opacity: 1,
    transition: theme2.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));
var Switch_default = Toggle;
export {
  FontAwesomeScalableIcon,
  IconButton_default as IconButton,
  Switch_default as Switch,
  theme as baseTheme,
  themeOptions as baseThemeOptions,
  themeOptions2 as darkThemeOptions,
  getThemeOptions as getBaseThemeOptions,
  themeOptions3 as lightThemeOptions,
  styled
};
