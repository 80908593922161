import type { Appointment } from '@mend/types/appointment';
import type { SxProps } from '@mui/material';

import InformationCard from './InformationCard';

export default function PatientInformation({
  appointment,
  sx,
}: {
  appointment: Appointment;
  withActions?: boolean; //TODO Implement information actions here
  sx?: SxProps;
}): JSX.Element {
  return (
    <InformationCard<Appointment>
      paperSx={sx}
      title="Patient Information"
      model={appointment}
      items={[
        {
          id: 'name',
          name: 'Name',
          accessor: ({ patient }) => patient.firstName,
        },
        {
          id: 'middle',
          name: 'Middle Name',
          accessor: ({ patient }) => patient?.middleName ?? '--',
        },
        {
          id: 'last',
          name: 'Last Name',
          accessor: ({ patient }) => patient.lastName,
        },
        {
          id: 'email',
          name: 'Email Address',
          accessor: (appointment) => appointment.patient.email,
        },
        {
          id: 'mobile',
          name: 'Mobile',
          accessor: (appointment) => appointment.patient.mobile ?? '--',
        },
        {
          id: 'birthdate',
          name: 'Birthdate',
          accessor: (appointment) => {
            const [year, month, day] = appointment.patient.birthDate.split('-');
            return `${month}/${day}/${year}`;
          },
        },
        {
          id: 'gender',
          name: 'Gender',
          accessor: (appointment) => appointment.patient.gender,
        },
      ]}
    />
  );
}
