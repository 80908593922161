import type { User } from '@mend/types/user';
import type * as React from 'react';
import type { CellProps } from '#components/DataTable';
import type { CellRendererAvailableProvidersProps, SlotRange } from '../utils';

import { Button, Stack } from '@mui/material';

import { formatUTCStringWithTimezone } from '#utils/dates';

export default function OpenSlots({
  row: { original: user, id },
  customProps,
}: CellProps<
  User,
  undefined,
  CellRendererAvailableProvidersProps
>): React.ReactNode {
  const handleAction = (slotRange: SlotRange) => {
    customProps?.handleSlotSelection(slotRange);
    customProps?.handleUserSelection(user);
  };

  return (
    <Stack marginTop={customProps.isInCard ? 2 : 0} overflow="auto">
      {(user.availableSlots ?? []).map((slot) => {
        const isSlotSelected =
          slot.endDate === customProps.selectedSlot.endDate &&
          id === customProps.selectedSlot.id;
        return (
          <Button
            key={slot.endDate}
            variant={isSlotSelected ? 'contained' : 'outlined'}
            onClick={() =>
              handleAction({
                startDate: slot.startDate,
                endDate: slot.endDate,
                id: id,
              })
            }
          >
            {formatUTCStringWithTimezone(
              slot.startDate,
              customProps.timezone.split('-')[0] as string, // FIXME - enforce type check
              'MMM dd hh:mma'
            )}
          </Button>
        );
      })}
    </Stack>
  );
}
