import type { User } from '@mend/types/user';
import type { CellProps } from '#components/DataTable';
import type { CellRendererAllUsersProps } from '../utils';

import { Radio } from '@mui/material';

import { getFullName } from '#utils/user';

export default function AllUsersAction({
  row: { original: user },
  customProps,
}: CellProps<User, undefined, CellRendererAllUsersProps>): JSX.Element {
  return (
    <Radio
      value={user.id}
      onChange={() => customProps?.handleUserSelection(user)}
      inputProps={{ 'aria-label': getFullName(user) }}
    />
  );
}
