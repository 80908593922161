import type { ZoomNetworkQualityLevel } from './Session.utils';

import { and, assertEvent, assign, not, or, setup } from 'xstate';

import ringingFileUrl from '#assets/sounds/ringing.mp3';
import { config } from '#config';

function redirectBackToDashboard() {
  window.location.replace(`${config.PORTAL_BASE_URL}/dashboard`);
}

function redirectBackToKiosk() {
  window.location.replace(`${config.PORTAL_BASE_URL}/kiosk`);
}

const defaultBadNetworkQualityTimeoutMs = 10_000; // 10s - Configurable

/**
 * State machine for the video session.
 *
 * In every case where we call the `redirectBackToX` action, the target
 * isn't really needed for functionality but for visualization in the machine's
 * diagram, nothing changes if we remove it.
 */
export const sessionMachine = setup({
  types: {
    context: {} as {
      /**
       * Flag to determine if the notification has been dispatched to avoid
       * processing further data from network quality events.
       */
      badNetworkQualityNotified: boolean;
      /**
       * Threshold used in the calculations for the bad network quality
       * notification. Saved in context to access it in guards/delays.
       */
      badNetworkQualityTimeoutMs: number;
      /**
       * Timestamps of bad network quality events.
       */
      badNetworkQualityTimestamps: number[];
      /**
       * Timestamp used to calculate the proper delay when a bad event is
       * received. If consecutive bad events are received, only the first one
       * should be stored and must be reset whenever a good one is received.
       */
      badNetworkQualityInitialTimestamp: number | null;
      phoneNumber: string | null;
      ringer: HTMLAudioElement;
    },
    events: {} as
      | { type: 'join.via.web' }
      | { type: 'visit.init.error' }
      | { type: 'visit.ended'; force?: boolean }
      | { type: 'visit.networkQualityChanged'; level: ZoomNetworkQualityLevel }
      | { type: 'checkout.action' }
      | {
          type: 'visit.callout.dialing';
          inviteeName: string;
          inviteeNumber: string;
        }
      | { type: 'visit.callout.ringing' }
      | { type: 'visit.callout.accepted' }
      | { type: 'visit.callout.rejected' }
      | { type: 'visit.callout.missed' }
      | { type: 'visit.callout.error' },
  },
  actions: {
    redirectBackToDashboard,
    redirectBackToKiosk,
    clearBadNetworkQualityInitialTimestamp: assign({
      badNetworkQualityInitialTimestamp: null,
    }),
    saveBadNetworkQualityTimestamp: assign(({ context }) => {
      const now = Date.now();
      return {
        badNetworkQualityTimestamps:
          context.badNetworkQualityTimestamps.concat(now),
        badNetworkQualityInitialTimestamp:
          context.badNetworkQualityInitialTimestamp === null
            ? now
            : context.badNetworkQualityInitialTimestamp,
      };
    }),
    saveBadNetworkQualityNotifiedFlag: assign({
      badNetworkQualityNotified: true,
    }),
    showBadNetworkQualityToast: () => void 0, // Provide implementation in machine consumer
    playRinger: ({ context }) => {
      context.ringer.loop = true;
      context.ringer.play().catch((error) => {
        console.error('Error playing sound:', error);
      });
    },
    stopRinger: ({ context }) => {
      context.ringer.pause();
      context.ringer.currentTime = 0;
    },
    setPhoneNumber: assign({
      phoneNumber: ({ context, event }) =>
        event.type === 'visit.callout.dialing'
          ? event.inviteeNumber
          : context.phoneNumber,
    }),
    clearPhoneNumber: assign({ phoneNumber: null }),
    showCallingToast: () => void 0, // Provide implementation in machine consumer
    showCallAcceptedToast: () => void 0, // Provide implementation in machine consumer
    showDisconnectedToast: () => void 0, // Provide implementation in machine consumer
    showInvalidNumberToast: () => void 0, // Provide implementation in machine consumer
    showCallFailedToast: () => void 0, // Provide implementation in machine consumer
  },
  guards: {
    isOpenedInPip: () => false, // Provide implementation in machine consumer
    isAdHocVisit: () => false, // Provide implementation in machine consumer
    isKioskUser: () => false, // Provide implementation in machine consumer
    isStaffPlus: () => false, // Provide implementation in machine consumer
    isEndAllAndCheckOutEnabled: () => false, // Provide implementation in machine consumer
    canPatientJoinViaApp: () => true, // Provide implementation in machine consumer
    hasDispatchedBadNetworkQualityNotification: ({ context }) =>
      context.badNetworkQualityNotified,
    shouldForce: ({ event }) => {
      assertEvent(event, 'visit.ended');
      return event.force ?? false;
    },
    isGoodNetworkQualityLevel: ({ event }) => {
      assertEvent(event, 'visit.networkQualityChanged');
      return event.level >= 2;
    },
    /**
     * No need to check for the level because this guard should always be used
     * with `isGoodNetworkQualityLevel` as part of a higher-level guard.
     */
    hasReachedBadNetworkQualityThreshold: ({ context }) => {
      /**
       * We only take the last two items of the array to check for the
       * threshold because the incoming event would account for the third (the
       * timestamp is saved after the guard is evaluated).
       */
      const threshold = Date.now() - context.badNetworkQualityTimeoutMs;
      return (
        context.badNetworkQualityTimestamps
          .slice(-2)
          .reduce((acc, curr) => acc + (curr >= threshold ? 1 : 0), 0) === 2
      );
    },
  },
  delays: {
    badNetworkQualityTimeoutMs: ({ context }) => {
      /**
       * Should never be reached because by the time this delay runs the bad
       * timestamp should've been saved already.
       */
      if (!context.badNetworkQualityInitialTimestamp) {
        return context.badNetworkQualityTimeoutMs;
      }
      return (
        context.badNetworkQualityTimeoutMs -
        (Date.now() - context.badNetworkQualityInitialTimestamp)
      );
    },
  },
}).createMachine({
  context: {
    badNetworkQualityNotified: false,
    badNetworkQualityInitialTimestamp: null,
    badNetworkQualityTimeoutMs: defaultBadNetworkQualityTimeoutMs,
    badNetworkQualityTimestamps: [],
    phoneNumber: null,
    ringer: new Audio(ringingFileUrl),
  },
  initial: 'init',
  states: {
    /**
     * Initial state used to determine the "true" initial state. Since initial
     * states can't be dynamic/provided, an eventless transition that happens
     * as soon as the machine is initialized gets the job done.
     */
    init: {
      always: [
        {
          guard: and([
            not('isStaffPlus'),
            not('isKioskUser'),
            'canPatientJoinViaApp',
          ]),
          target: 'joinMethod',
        },
        { target: 'active' },
      ],
    },
    /**
     * The guest gets to choose which method they'd like to join with.
     */
    joinMethod: {
      on: {
        'join.via.web': 'active',
      },
    },
    /**
     * The video session is in progress.
     */
    active: {
      type: 'parallel',
      on: {
        'visit.init.error': 'error',
        'visit.ended': [
          {
            guard: and([
              'isStaffPlus',
              'isEndAllAndCheckOutEnabled',
              not('isAdHocVisit'),
              not('shouldForce'),
            ]),
            target: 'checkOut',
          },
          {
            guard: and(['isKioskUser', not('isOpenedInPip')]),
            actions: 'redirectBackToKiosk',
            target: 'ended',
          },
          {
            guard: and(['isStaffPlus', not('isOpenedInPip')]),
            actions: 'redirectBackToDashboard',
            target: 'ended',
          },
          { target: 'ended' },
        ],
      },
      states: {
        networkState: {
          initial: 'goodNetworkQuality',
          on: {
            'visit.networkQualityChanged': [
              {
                guard: or([
                  'hasDispatchedBadNetworkQualityNotification',
                  'isGoodNetworkQualityLevel',
                  'isStaffPlus',
                  'isKioskUser',
                ]),
                target: '.goodNetworkQuality',
              },
              {
                guard: and([
                  not('isGoodNetworkQualityLevel'),
                  'hasReachedBadNetworkQualityThreshold',
                ]),
                target: '.badNetworkQualityNotificationReady',
              },
              { target: '.badNetworkQuality' },
            ],
          },
          states: {
            goodNetworkQuality: {
              entry: 'clearBadNetworkQualityInitialTimestamp',
            },
            badNetworkQuality: {
              entry: 'saveBadNetworkQualityTimestamp',
              after: {
                badNetworkQualityTimeoutMs:
                  'badNetworkQualityNotificationReady',
              },
            },
            badNetworkQualityNotificationReady: {
              type: 'final',
            },
          },
          onDone: {
            actions: [
              'showBadNetworkQualityToast',
              'saveBadNetworkQualityNotifiedFlag',
            ],
          },
        },
        callState: {
          initial: 'idle',
          states: {
            idle: {
              entry: ['stopRinger', 'clearPhoneNumber'],
              on: {
                'visit.callout.dialing': {
                  target: 'dialing',
                  actions: 'setPhoneNumber',
                },
                'visit.callout.rejected': {
                  // TODO: Try and come up with some guard to prevent this when dialing out via Zoom UI
                  actions: 'showDisconnectedToast',
                },
              },
            },
            dialing: {
              entry: 'showCallingToast',
              on: {
                'visit.callout.ringing': {
                  target: 'ringing',
                },
                'visit.callout.rejected': {
                  target: 'idle',
                  actions: 'showInvalidNumberToast',
                },
                'visit.callout.error': {
                  target: 'idle',
                  actions: 'showCallFailedToast',
                },
              },
            },
            ringing: {
              entry: 'playRinger',
              on: {
                'visit.callout.accepted': {
                  target: 'idle',
                  actions: 'showCallAcceptedToast',
                },
                'visit.callout.rejected': {
                  target: 'idle',
                  actions: 'showDisconnectedToast',
                },
                'visit.callout.missed': {
                  target: 'idle',
                  actions: 'showDisconnectedToast',
                },
              },
              after: {
                // TODO: Look into how long this timeout should really be
                15000: {
                  target: 'idle',
                },
              },
            },
          },
        },
      },
    },
    /**
     * The video session just ended but the user has the possibility to check
     * out the appointment.
     */
    checkOut: {
      on: {
        'checkout.action': [
          {
            guard: not('isOpenedInPip'),
            actions: 'redirectBackToDashboard',
            target: 'ended',
          },
          { target: 'ended' },
        ],
      },
    },
    /**
     * The video session reached an unrecoverable error.
     */
    error: {
      type: 'final',
    },
    /**
     * The video session has ended gracefully.
     */
    ended: {
      type: 'final',
    },
  },
});
