// src/colors/amber.ts
var amber_default = {
  50: "#fbf3d8",
  100: "#f5e09d",
  200: "#eccd61",
  300: "#e2bb19",
  400: "#e19f58",
  500: "#da914d",
  600: "#be8952",
  700: "#a77036",
  800: "#744209",
  900: "#592400"
};

// src/colors/blue.ts
var blue_default = {
  50: "#d0f1ff",
  100: "#a5ebff",
  200: "#8cd6fe",
  300: "#63c6fc",
  400: "#4bb9fb",
  500: "#41adf9",
  600: "#3e9fea",
  700: "#398bd5",
  800: "#357ac1",
  900: "#2d5a9e"
};

// src/colors/green.ts
var green_default = {
  50: "#eefff5",
  100: "#c6fbda",
  200: "#96e9b5",
  300: "#84f3b1",
  400: "#65ed9f",
  500: "#45e78d",
  600: "#57d489",
  700: "#31c272",
  800: "#29b066",
  900: "#2f7e4f",
  1e3: "#276f44",
  1100: "#1e5c37"
};

// src/colors/grey.ts
var grey_default = {
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#eeeeee",
  300: "#e0e0e0",
  400: "#bdbdbd",
  500: "#9e9e9e",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
  A100: "#f5f5f5",
  A200: "#eeeeee",
  A400: "#bdbdbd",
  A700: "#616161"
};

// src/colors/indigo.ts
var indigo_default = {
  50: "#e3ecfb",
  100: "#bfe0ff",
  200: "#97ceff",
  300: "#409cff",
  400: "#3d8ef0",
  500: "#0c4cd7",
  600: "#0044cc",
  700: "#113da5",
  800: "#003399",
  900: "#002266"
};

// src/colors/lavander.ts
var lavander_default = {
  50: "#dee6fe",
  100: "#bcc9f4",
  200: "#90a7e7",
  300: "#7a88c7",
  400: "#5d6ebb",
  500: "#4055b0",
  600: "#3a4da6",
  700: "#31429a",
  800: "#29398e",
  900: "#1c2778"
};

// src/colors/mend-teal.ts
var mend_teal_default = {
  50: "#d1f4ff",
  100: "#afdff7",
  200: "#92c5e2",
  300: "#71accc",
  400: "#5698ba",
  500: "#4185a6",
  600: "#287697",
  700: "#166280",
  // Primary
  800: "#00526e",
  900: "#003f57",
  1e3: "#012331"
  // Dark
};

// src/colors/orange.ts
var orange_default = {
  50: "#fff6ed",
  100: "#ffe1c2",
  200: "#ffcc80",
  300: "#ffb74d",
  400: "#ffa726",
  500: "#ff9800",
  600: "#fb8c00",
  700: "#f57c00",
  800: "#ef6c00",
  900: "#e65100",
  A100: "#ffd180",
  A200: "#ffab40",
  A400: "#ff9100",
  A700: "#ff6d00"
};

// src/colors/pink.ts
var pink_default = {
  50: "#fde5ec",
  100: "#fabdd0",
  200: "#f792b0",
  300: "#f46791",
  400: "#ef4377",
  500: "#ec2460",
  600: "#db215d",
  700: "#c51d58",
  800: "#b01855",
  900: "#8b114d",
  1e3: "#ec2663"
};

// src/colors/purple.ts
var purple_default = {
  50: "#f2e9f8",
  100: "#dec9ee",
  200: "#c8a4e4",
  300: "#b37dd8",
  400: "#a25fce",
  500: "#9140c3",
  600: "#883abd",
  700: "#7b31b4",
  800: "#712aac",
  900: "#5f1c9e"
};

// src/colors/red.ts
var red_default = {
  50: "#fff0ee",
  100: "#ffdede",
  200: "#ff9d9d",
  300: "#f97777",
  400: "#ff6a4b",
  500: "#f7482a",
  600: "#db3a21",
  700: "#bb2519",
  800: "#ae1e12",
  900: "#9f1205"
};

// src/colors/teal.ts
var teal_default = {
  50: "#ceffff",
  100: "#b7fffd",
  200: "#82ffff",
  300: "#47fcff",
  400: "#5cdae2",
  500: "#40d0dc",
  600: "#4aafb7",
  700: "#449a9e",
  800: "#258d93",
  900: "#1e6969"
};

// src/colors/yellow.ts
var yellow_default = {
  50: "#fffcf5",
  100: "#f5eebe",
  200: "#ede594",
  300: "#e5db6a",
  400: "#ded34a",
  500: "#d8cc26",
  600: "#e9cc36",
  700: "#c0a02a",
  800: "#846a0e",
  900: "#604c08"
};
export {
  amber_default as amber,
  blue_default as blue,
  green_default as green,
  grey_default as grey,
  indigo_default as indigo,
  lavander_default as lavander,
  mend_teal_default as mendTeal,
  orange_default as orange,
  pink_default as pink,
  purple_default as purple,
  red_default as red,
  teal_default as teal,
  yellow_default as yellow
};
