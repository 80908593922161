import type { Theme } from '@mend/mui';
import type { Appointment } from '@mend/types/appointment';
import type { User } from '@mend/types/user';
import type { Cell, Column } from 'react-table';
import type { CellRendererAvailableProvidersProps } from '#components/TransferVisitModal/utils';
import type { DateRange, Range } from '#types/dates';

import * as React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { addDays, differenceInYears, parse, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { ListDataTable } from '#components/DataTable';
import { DateRangePicker } from '#components/DatePickers';
import SearchBar from '#components/SearchBar';
import * as Cards from '#components/TransferVisitModal/Cards';
import * as Cells from '#components/TransferVisitModal/Cells';
import { globalFilter } from '#components/TransferVisitModal/utils';
import useAsync from '#hooks/async';
import usePlaceholder from '#hooks/placeholder';
import { getProviders } from '#services/api/user';
import {
  convertUtcStringToLocalDate,
  currentTimezone,
  formatUTCStringWithTimezone,
  getDatesByRange,
} from '#utils/dates';
import { getMappedUserTimezone } from '#utils/timezones.ts';
import { getFullName } from '#utils/user';

// eslint-disable-next-line react-refresh/only-export-components
export const ranges: Range[] = ['today', 'tomorrow', 'next-7', 'custom'];

const getStartOfDayFormattedDate = (date: Date) =>
  formatInTimeZone(startOfDay(date), 'UTC', 'yyyy-MM-dd HH:mm:ss');

type ProviderAvailabilityProps = {
  appointment: Appointment | null;
  patientTimezone: string | undefined;
  timezone: string;
  onTimezoneChange: (timezone: string) => void;
  getCellRenderedProps?: (
    cell: Cell<User, unknown>
  ) => CellRendererAvailableProvidersProps;
};

const providerColumns: Column<User>[] = [
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  {
    Header: 'Provider',
    id: 'name',
    accessor: getFullName,
    Cell: Cells.Name,
    Filter: () => null,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  {
    id: 'actions',
    Header: 'Open Slots',
    accessor: (user) => {
      const slot = user.availableSlots?.[0];
      return slot ? convertUtcStringToLocalDate(slot.startDate).getTime() : 0;
    },
    Cell: Cells.OpenSlots,
    Filter: () => null,
  },
];

const defaultFilters = [{ id: 'actions', value: { id: 'today' } }];

const defaultSortBy = [{ id: 'actions', desc: false }];

export function ProviderAvailability({
  getCellRenderedProps,
  appointment,
  patientTimezone,
  timezone,
  onTimezoneChange,
}: ProviderAvailabilityProps): JSX.Element {
  const {
    data: availableProvidersData,
    run: availableProvidersRun,
    status: availableProvidersStatus,
  } = useAsync<User[]>();

  const availableProviders = React.useMemo(
    () => availableProvidersData ?? [],
    [availableProvidersData]
  );

  const availableProvidersInstance = useTable<User>(
    {
      columns: providerColumns,
      data: availableProviders,
      initialState: {
        pageSize: 5,
        globalFilter: '',
        filters: defaultFilters,
        sortBy: defaultSortBy,
        pageIndex: 0,
      },
      manualFilters: true,
      globalFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    setFilter,
    state: { filters },
    setGlobalFilter: setSearchTerm,
  } = availableProvidersInstance;

  const searchTerm = typeof globalFilter !== 'string' ? '' : globalFilter;

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const dateFilterValue = React.useMemo(
    () => filters.find(({ id }) => id === 'actions')?.value as DateRange,
    [filters]
  );

  const dateParams = React.useMemo(() => {
    let [start, end] = getDatesByRange(dateFilterValue.id);

    if (start === null || end === null) {
      start = dateFilterValue.start!;
      end = dateFilterValue.end!;
    }

    return {
      start: getStartOfDayFormattedDate(start),
      end: getStartOfDayFormattedDate(addDays(end, 1)),
    };
  }, [dateFilterValue]);

  React.useEffect(() => {
    if (!appointment) {
      return;
    }

    const patientAge = differenceInYears(
      new Date(),
      parse(appointment.patient.birthDate, 'yyyy-MM-dd', new Date())
    );

    /**
     * - There is a bug in the API that returns providers with no available
     * slots so we filter those out here until that is fixed.
     *
     * - With a recent change of the API, we now return up to 100 available
     * slots so we pick only the first 3 to stay consistent with the old
     * dashboard.
     *
     * - We format the start and end dates of the available slots in order to
     * avoid doing it during render in `OpenSlots` for the selected slot.
     */
    void availableProvidersRun(
      getProviders({
        showInAppointmentFlow: 1,
        providerAvailability: 1,
        onlyAvailable: 1,
        appointmentTypeId: appointment.appointmentType?.id,
        eventStartDate: dateParams.start,
        eventEndDate: dateParams.end,
        patientAge,
        excludeUsers: [appointment.providerId],
      }).then((providers) =>
        providers
          .filter(
            ({ availableSlots }) =>
              Array.isArray(availableSlots) && availableSlots.length > 0
          )
          .map((provider) => ({
            ...provider,
            availableSlots: (provider.availableSlots ?? [])
              .slice(0, 3)
              .map((slot) => ({
                ...slot,
                startDate: formatUTCStringWithTimezone(slot.startDate, 'UTC'),
                endDate: formatUTCStringWithTimezone(slot.endDate, 'UTC'),
              })),
          }))
      )
    );
  }, [appointment, dateParams, availableProvidersRun]);

  const placeholder = usePlaceholder('Provider information');

  return (
    <>
      <Stack
        component={FormControl}
        fullWidth
        justifyContent="space-between"
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
      >
        <FormLabel data-testid="timezone" sx={{ mb: 0, fontWeight: 700 }}>
          {/* TODO tech debt `!` added for the port */}
          Timezone: {getMappedUserTimezone(timezone.split('-')[0]!)}
        </FormLabel>

        <RadioGroup
          row={!isMobile}
          value={timezone}
          onChange={(event) => onTimezoneChange(event.target.value)}
          aria-label="Timezones"
        >
          <FormControlLabel
            value={`${currentTimezone}-mine`}
            control={<Radio />}
            label="Your current timezone"
          />
          <FormControlLabel
            value={`${patientTimezone}-patient`}
            control={<Radio />}
            label="Patient timezone"
          />
        </RadioGroup>
      </Stack>

      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
        my={2}
      >
        <Grid item xs>
          <SearchBar
            fullWidth
            placeholder={placeholder}
            value={searchTerm}
            onChange={setSearchTerm}
          />
        </Grid>

        <Grid item xs="auto">
          <DateRangePicker
            range={dateFilterValue}
            ranges={ranges}
            onRangeChange={(range: DateRange) => {
              /**
               * Reset to the current user's timezone when the date changes
               * because this will trigger a re-fetch of the data that uses
               * such timezone as offset.
               */
              onTimezoneChange(`${currentTimezone}-mine`);
              setFilter('actions', range);
            }}
          />
        </Grid>
      </Grid>

      <ListDataTable<User, CellRendererAvailableProvidersProps>
        instance={availableProvidersInstance}
        status={availableProvidersStatus}
        getCellRendererProps={getCellRenderedProps}
        isEmptyMessage="No providers found."
        isRejectedMessage="There was an error loading the providers."
        Card={Cards.SharedUsersCard}
      />
    </>
  );
}
