import type { Appointment } from '@mend/types/appointment';
import type { TypographyProps } from '@mui/material';

import { styled } from '@mend/mui';
import { Typography } from '@mui/material';

import * as AugmentedLink from '#components/AugmentedLink.tsx';
import { getProviderDisplayName } from '#utils/functions.ts';

export const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr', // 1/3 for label, 2/3 for value
  gap: theme.spacing(2),
  alignItems: 'center', // vertically align items in the center

  // Mobile styles using breakpoints
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr', // Change to a single column on small screens
  },
}));

export const Label = styled((props: TypographyProps) => (
  <Typography variant="body1" {...props} />
))({ '&::after': { content: '":"' } });

export const Value = styled('div')({
  overflow: 'hidden',
  wordWrap: 'break-word', // Ensures long words wrap to the next line
  overflowWrap: 'break-word', // Handles text breaking for all browsers
});

export function Provider(props: { provider: Appointment['provider'] }) {
  return (
    <>
      <Label>Provider</Label>
      <Value>
        <Typography variant="body2">
          {getProviderDisplayName(props.provider)}
        </Typography>
      </Value>
    </>
  );
}

export function AppointmentID(props: { id: Appointment['id'] }) {
  return (
    <>
      <Label>Appointment ID</Label>
      <Value>
        <AugmentedLink.Root href={props.id} tagId="appointmentId">
          <AugmentedLink.Label />
          <AugmentedLink.Copy />
        </AugmentedLink.Root>
      </Value>
    </>
  );
}

export function DirectVideoLink() {
  return (
    <>
      <Label>Direct Video Link</Label>
      <Value>
        <AugmentedLink.Root href={window.location.href} tagId="directVideoLink">
          <AugmentedLink.Label asLink text={window.location.href} />
          <AugmentedLink.Copy />
        </AugmentedLink.Root>
      </Value>
    </>
  );
}
