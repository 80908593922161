import { useTheme } from '@mui/material/styles';

import useMediaQuery from './media-query';

/**
 * Simple hook that wraps the useMediaQuery hook in order to detect if the user
 * is in a mobile device. While we have a width query as default, we could use
 * something else like '(pointer: fine)' if needed.
 */
export default function useIsMobile(query?: string): boolean {
  const theme = useTheme();
  return useMediaQuery(
    query ?? `(max-width: ${theme.breakpoints.values.sm}px)`
  );
}
