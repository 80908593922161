import type { User } from '@mend/types/user';
import type { CellProps } from '#components/DataTable';
import type { CellRendererAllUsersProps } from '../utils';

import Name from '../Name';

export default function NameCell({
  row: { original: user },
}: CellProps<User, string, CellRendererAllUsersProps>): JSX.Element {
  return <Name user={user} />;
}
