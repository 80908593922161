import type { Appointment } from '@mend/types/appointment';

import { Box, TextField } from '@mui/material';
import { useMachine } from '@xstate/react';

import { notesMachine } from './Notes.machine';
import { WidgetCard } from './WidgetCard';

const textFieldInputProps = {
  sx: {
    alignItems: 'start',
    height: '100%',
  },
};

const textFieldSx = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
};

interface NotesProps {
  appointmentId: number;
  note: { id?: number; content: string } | undefined;
  onAppointmentUpdate: (appointment: Appointment) => void;
  saveNoteDelayMs?: number;
  fetchAppointmentDelayMs?: number;
}

export function Notes({
  appointmentId,
  note,
  onAppointmentUpdate,
  saveNoteDelayMs = 2_000,
  fetchAppointmentDelayMs = 25_000,
}: NotesProps) {
  const [snapshot, send] = useMachine(
    notesMachine.provide({
      actions: {
        updateAppointmentData: ({ event }) => {
          if (event.type === 'appointment.updated') {
            onAppointmentUpdate(event.appointment);
          }
        },
      },
      delays: {
        saveNotes: saveNoteDelayMs,
        fetchAppointment: fetchAppointmentDelayMs,
      },
    }),
    { input: { appointmentId, note } }
  );

  return (
    <WidgetCard title="Appointment Notes" fullHeight>
      <Box height="100%" display="flex" flexDirection="column">
        <TextField
          variant="outlined"
          multiline
          value={snapshot.context.note.content}
          onChange={(event) =>
            send({ type: 'note.changed', value: event.target.value })
          }
          label="Add Notes"
          placeholder="Type your notes here..."
          fullWidth
          helperText="Notes do not auto-sync to the EHR. You may copy them over."
          InputProps={textFieldInputProps}
          sx={textFieldSx}
        />
      </Box>
    </WidgetCard>
  );
}
