import type { Theme } from '@mend/mui';

import { styled } from '@mend/mui';
import {
  AccessTimeOutlined,
  CalendarTodayOutlined,
  EditOutlined,
} from '@mui/icons-material';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { getMappedUserTimezone } from '#utils/timezones';

const DateTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  paddingRight: 3,
}));

// TODO tech debt added for the port
// eslint-disable-next-line react-refresh/only-export-components
export const renderFormattedDate = (
  dateSelected: Date,
  selectedFormat = 'hh:mm aaa'
): string => format(dateSelected, selectedFormat);

type Range = {
  start: string;
  end: string;
};

export type ScheduledUserProps<T extends Range = Range> = {
  range: T;
  userName: string;
  timezone: string;
  onOpenCalendar?: () => void;
};

export default function ScheduledUser<T extends Range>({
  range,
  userName,
  timezone,
  onOpenCalendar,
}: ScheduledUserProps<T>): JSX.Element {
  const realStartDate = utcToZonedTime(new Date(range.start), timezone);
  const realEndDate = utcToZonedTime(new Date(range.end), timezone);
  /**
   * Use native Stack's reponsive direction once mui is updated to latest v5.
   * @see https://github.com/mui/material-ui/pull/33548
   */
  const isSmallPlus = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm')
  );

  return (
    <Stack
      direction={isSmallPlus ? 'row' : 'column'}
      spacing={2}
      alignItems={isSmallPlus ? 'center' : 'flex-start'}
      justifyContent="space-between"
      width="100%"
    >
      <Stack direction="column" data-testid="schedule-information">
        <Stack alignItems="center" direction="row">
          <AccessTimeOutlined color="primary" />
          <DateTypography>
            {renderFormattedDate(realStartDate, 'MMM d, yyyy')}
          </DateTypography>
        </Stack>

        <Stack alignItems="center" direction="row" mt={0}>
          <CalendarTodayOutlined color="primary" />
          <DateTypography>{`${renderFormattedDate(
            realStartDate
          )} - ${renderFormattedDate(realEndDate)}`}</DateTypography>
          {`for ${userName} (${getMappedUserTimezone(timezone)})`}
        </Stack>
      </Stack>

      {onOpenCalendar && (
        <Button
          color="primary"
          fullWidth={!isSmallPlus}
          startIcon={<EditOutlined />}
          onClick={onOpenCalendar}
        >
          Edit
        </Button>
      )}
    </Stack>
  );
}
