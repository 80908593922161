import type { Address } from '@mend/types/address';
import type { Provider } from '@mend/types/appointment';

import { getFullName } from '#utils/user.ts';

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmailValid(value: string): boolean {
  return REGEX_EMAIL.test(value);
}

/**
 * Based on https://stackoverflow.com/a/51564734
 */
export function hex2rgba(hex: string, alpha = 1): string {
  const matches = hex.match(/\w\w/g);
  if (!matches || matches.length !== 3) {
    throw new Error(`Could not convert \`${hex}\` to rgba`);
  }
  const [r, g, b] = matches.map((x) => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function removeSpecialCharacters(value: string): string {
  return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/\s/]/g, '');
}

export function getFullOrgLocationName(address: Address): string {
  const { locationName } = address;

  if (address.locationNameOnly && locationName) {
    return String(locationName);
  }

  const cityPart: (string | number)[] = [];
  if (address.street) cityPart.push(address.street);
  if (address.city) cityPart.push(address.city);

  const statePart: (string | number)[] = [];
  if (address.state) statePart.push(address.state);
  if (address.postal) statePart.push(address.postal);

  const fullAddress = [cityPart.join(' '), statePart.join(' ')].join(', ');

  if (locationName) {
    return `${locationName}: ${fullAddress}`;
  }

  return fullAddress;
}

function getTitle(provider: Provider): string | null {
  switch (provider.credential) {
    case 'MD':
    case 'DO':
      return 'Dr.';
    default:
      return null;
  }
}

export function getProviderDisplayName(provider: Provider): string {
  if (!provider) {
    return 'No provider loaded';
  }

  const title = getTitle(provider);
  const fullName = getFullName(provider);

  return title ? `${title} ${fullName}` : fullName;
}
