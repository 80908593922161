import type { Group } from '@mend/types/group';
import type { User as ExistingUser } from '@mend/types/user';
import type { GetUsersParams } from '#services/api/user';
import type { TemporaryUser, User } from './UsersAutocomplete.types';

import { blue, mendTeal, purple, teal } from '@mend/mui/colors';
import { isPossiblePhoneNumber as libIsPossiblePhoneNumber } from 'libphonenumber-js/min';

import { hex2rgba } from '#utils/functions';
import { getCountryCode } from '#utils/phone.ts';
import { getFullName } from '#utils/user';

export function isGroup(value: Group | User): value is Group {
  return (value as Group).users !== undefined;
}

export function isTemporaryUser(value: Group | User): value is TemporaryUser {
  return value.id === null;
}

export function isExistingUser(value: Group | User): value is ExistingUser {
  return !isGroup(value) && !isTemporaryUser(value);
}

export const isPossiblePhoneNumber = (value: string): boolean =>
  libIsPossiblePhoneNumber(value, getCountryCode(value));

export function isValueNotSelected(value: unknown, expected: string): boolean {
  return (
    !Array.isArray(value) ||
    value.every((option) =>
      // TODO explain/fix type error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      isTemporaryUser(option) ? option.value !== expected : true
    )
  );
}

export function getUserFormattedDOB(dob: string): string {
  if (!dob) return dob;
  const [year, month, day] = dob.split('-');
  return `${month}/${day}/${year}`;
}

export const getOptionLabel = (option: User | Group): string =>
  isGroup(option)
    ? option.name
    : isExistingUser(option)
      ? getFullName(option)
      : option.formattedValue;

export const isOptionEqualToValue = (
  option: User | Group,
  value: User | Group
): boolean => {
  if (
    (isGroup(option) && isGroup(value)) ||
    (isExistingUser(option) && isExistingUser(value))
  ) {
    return option.id === value.id;
  }
  if (isTemporaryUser(option) && isTemporaryUser(value)) {
    return option.value === value.value;
  }
  return false;
};

export const defaultGetUsersParams = (): Record<string, never> => ({});

export const defaultParams: GetUsersParams = {
  order: 'lastName ASC',
  limit: 50,
};

const rolesWithDetails: ExistingUser['role'][] = [
  'Provider',
  'Provider Admin',
  'Staff',
  'Org Admin',
  'Patient',
  'Ward',
];

export const getUsersInGroup = (group: Group): string =>
  group.users.map((groupUser) => getFullName(groupUser.user)).join(', ');

export const shouldDisplayRoleDetails = (role: ExistingUser['role']): boolean =>
  rolesWithDetails.includes(role);

export function getRoleColor(role: ExistingUser['role']): string | undefined {
  switch (role) {
    case 'Provider':
    case 'Provider Admin':
      return teal[800];
    case 'Staff':
    case 'Org Admin':
      return blue[800];
    case 'Patient':
    case 'Ward':
      return purple[800];
    default:
      return undefined;
  }
}

const rolesWithExtraDetails: ExistingUser['role'][] = [
  'Provider',
  'Provider Admin',
  'Patient',
  'Ward',
];

export const shouldDisplayRoleExtraDetails = (
  role: ExistingUser['role']
): boolean => rolesWithExtraDetails.includes(role);

export function getRoleExtraDetails(user: ExistingUser): string | null {
  let details: string | null;

  switch (user.role) {
    case 'Provider':
    case 'Provider Admin':
      details = user.credentialName;
      break;
    case 'Patient':
    case 'Ward':
      details = getUserFormattedDOB(user.birthDate);
      break;
    default:
      return null;
  }

  // `credentialName` might be null hence the check
  return details ? ` | ${details}` : null;
}

/**
 * When a ward is created, it can be created with no contact email so the email
 * defaults to an invalid, randomly generated value with an example.com domain,
 * in such cases, we don't want to display the randomly generated email if no
 * contact email is present.
 */
export const shouldDisplayEmail = (user: ExistingUser): boolean =>
  user.role !== 'Ward' || Boolean(user.contact);

export const getEmail = (user: ExistingUser): string =>
  user.role !== 'Ward' ? user.email : String(user.contact);

export const DEFAULT_HELPER_TEXT = 'Search by name, email, or phone number';

export const DEFAULT_CUSTOM_PHONE_NUMBER_HELPER_TEXT =
  'For international phone numbers, dial country code followed by area code and phone number';

export const DEFAULT_HELPER_TEXT_WITH_CUSTOM_PHONE_NUMBER = `${DEFAULT_HELPER_TEXT}. ${DEFAULT_CUSTOM_PHONE_NUMBER_HELPER_TEXT}.`;

export const INPUT_EMPTY = 'Start typing to see results';

export const NO_RESULTS = 'No results';

export const selectedBgColor = hex2rgba(mendTeal[700], 0.08);
